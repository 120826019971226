import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BusyIndicator from "../components/BusyIndicator";
import { useApi } from "../security";

export default function ReservationConfirmation(){
    const { id } = useParams();
    const api = useApi();

    const [isBusy, setIsBusy]= useState(true);
    const [appt, setAppt] = useState();
    const [isError, setIsError] = useState(false);

    useEffect(() =>{
        api.appointments.getAppointment(id)
            .then(r =>{
                console.log('get appt:',r.data); 
                if (r.data){
                    setAppt(r.data);
                } else {
                    setIsError(true);
                } 
                setIsBusy(false);
            });
    },[]); 

    if (isBusy){
        return <BusyIndicator/>;
    }

    if (isError){
        return (<div className="alert alert-danger">An error has prevented the reservation from completing. Please contact customer service.</div>);
    }

    return (<div className="page">
        <div className="alert alert-success">
            <p>You have a Premium Doc reservation with Dr. {appt.doctor.fullName} at {new Date(appt.appointmentDate).toDateString()}. The facility will contact you for additional details and to confirm and schedule your appointment.</p>
            <p>If you supplied an email address a confirmation email was also sent with details regarding the reservation.</p>
        </div>
    </div>);
}