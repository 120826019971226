import React from 'react';
import ReviewStars from './ReviewStars';

const ReviewSummary = ({ score, count }) => {
    return (
        <div className="review-summary">
            <ReviewStars score={score}/>
            <span className="count">{count} reviews</span>
        </div>
        );
};

export default ReviewSummary;