import { useEffect, useState } from "react";
import Alert from "../components/Alert";
import BusyIndicator from "../components/BusyIndicator";
import { useApi, useAuth } from "../security";
import SearchUsers from "../components/SearchUsers";
import { Tab, Tabs } from "../components/Navigation";
import TaskTable from "../components/TaskTable";
import SearchAccounts from "../components/SearchAccounts";
import SearchOrganizations from "../components/SearchOrganizations";
import OrganizationManagement from "../components/OrganizationManagement";

export default function HomeInternalUsers(props) {
    const api = useApi();
    const { auth } = useAuth();
    const [isBusy, setIsBusy] = useState(true);
    const [error, setError] = useState();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        api.users.tasks()
            .then(r => {
                setIsBusy(false);
                setUsers(r.data);
                console.log('TASKS:', r.data);
            }).catch(e => setError(e.toString()));
    }, []);

    if (isBusy) {
        return (
            <>
                <Alert error={error} />
                <BusyIndicator />
            </>
        );
    }

    return (
        <div className="tw-m-10 tw-mt-2">
            <Tabs id='tasks'>
                <Tab title='New Users'>
                    <TaskTable users={users} error={error} />
                </Tab>
                <Tab title='Find Users'>
                    <SearchUsers />
                </Tab>
                <Tab title='Review Accounts'>
                    <SearchAccounts />
                </Tab>
                <Tab title='Practices/Medical Groups'>
                    <OrganizationManagement />
                </Tab>
            </Tabs>
        </div>
    );
}