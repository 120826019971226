import { useObjectState } from "../hooks";

export default function Contact(props){
    const user = props.user || {};

    const [contactName, setContactName] = useObjectState(user, 'contactName');
    const [contactEmail, setContactEmail] = useObjectState(user, 'contactEmail');
    const [contactPhone, setContactPhone] = useObjectState(user, 'contactPhone');

    
    return (
        <>
        <div>
            <div className="row">
                <div className="col">
                    <label>Name</label>
                    <input type="text" className="form-control" value={contactName} onChange={e => setContactName(e.target.value)}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Email address</label>
                    <input type="text" className="form-control" value={contactEmail} onChange={e => setContactEmail(e.target.value)}/>
                </div>
                <div className="col">
                    <label>Phone</label>
                    <input type="text" className="form-control" value={contactPhone} onChange={e => setContactPhone(e.target.value)}/>
                </div>
            </div>
        </div>
        </>
        );
}