import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth, useApi } from "../security";
import { AuthenticationStatus, Roles } from "../types";
import Alert from "../components/Alert";
import BusyIndicator from "../components/BusyIndicator";
import ChangePassword from "./ChangePassword";
import Card from "../components/Card";

export default function Login(props) {
    const api = useApi();
    const { setAuth } = useAuth();
    const nav = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();

    const [error, setError] = useState();
    const [loginFailed, setLoginFailed] = useState(false);

    const [isBusy, setIsBusy] = useState(false);
    const [authStatus, setAuthStatus] = useState(-1);

    const onConfirmEmail = props?.onConfirmEmail || function () { nav('/identity/confirm'); };
    const onForgotPassword = props?.onForgotPassword || function () { nav('/identity/forgotpassword'); };
    const onRegister = props?.onRegister || function () { nav('/identity/signup'); };

    function onUserAuthenticated(data) {
        if (props?.onAuthenticated) {
            props.onAuthenticated();
            return;
        }

        console.log('AUTH:', { ...data });

        if (Roles.isInRole(data.role, Roles.premiumDocAdministrator, Roles.premiumDocManager)) {
            nav('/users/tasks');
            return;
        }
        nav('/users');
    }

    function handleLoginSuccess(data) {
        console.log(data);
        setIsBusy(false);
        setAuthStatus(data.status);
        switch (data.status) {
            case AuthenticationStatus.authenticated:
                setAuth(data);
                onUserAuthenticated(data);
                break;
            case AuthenticationStatus.requiresEmailConfirmation:
                onConfirmEmail();
                break;
            case AuthenticationStatus.error:
                setError(data.error);
                break;
        }
        // var decoded = jwt_decode(data.accessToken);
        // if (decoded['emailconfirmed'] !== 'True'){
        //     onConfirmEmail();
        // }else{
        //     setAuth(data);
        //     onAuthenticated();
        // }
    }

    function handleLoginFailure(msg) {
        setError(msg);
        setLoginFailed(true);
        setIsBusy(false);
    }

    function clearError() {
        setError(null);
        setLoginFailed(false);
    }

    function submit(e) {
        e.preventDefault();
        if (email === '' || email === undefined) {
            setEmailError('Email address is required.');
            return;
        }
        if (password === '' || password === undefined) {
            setPasswordError('Password is required.');
            return;
        }

        clearError();
        setIsBusy(true);

        api.identity.authenticate(email, password)
            .then(r => handleLoginSuccess(r.data))
            .catch(e => handleLoginFailure(e?.response?.data ?? e?.message));
    }

    function signin(provider, token) {
        clearError();
        api.identity.authorize(provider, token)
            .then(r => handleLoginSuccess(r.data))
            .catch(e => setError(e?.response?.data ?? e?.message));
    }

    if (isBusy) {
        return (<BusyIndicator />);
    }

    if (authStatus === AuthenticationStatus.requiresPasswordChange) {
        return (<ChangePassword api={api} email={email} />);
    }

    return (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
            <Card className="tw-w-min tw-h-3/5" headerText="Login to your account">
                <Alert error={error} />
                <form onSubmit={e => submit(e)} className="tw-flex tw-flex-col tw-h-full tw-justify-around tw-m-4">
                    <div className="form-group tw-min-w-96">
                        <label>Email</label>
                        <input type="email" autoComplete="email" className="form-control" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                        {emailError && (<div className="alert alert-danger">{emailError}</div>)}
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
                        {passwordError && (<div className="alert alert-danger">{passwordError}</div>)}
                    </div>
                    <div className="form-group">
                        <div className="tw-flex tw-justify-between max-sm:tw-flex-col max-sm:tw-items-center">
                            <div className="tw-my-1">
                                <input type="checkbox" className="form-checkbox" /> Remember me
                            </div>
                            <a className="tw-my-1" href='#' onClick={onForgotPassword}>Forgot password?</a>
                        </div>
                    </div>
                    <div className="form-group d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-primary">Login now</button>
                    </div>
                    <div className="form-group mt-2 text-center">
                        Don't have an account? <a href='#' onClick={onRegister}>Join today!</a>
                    </div>
                </form>
                {/* <Divider text="OR"/>
                    <Google onSignin={signin} onError={setError}/> */}
            </Card>
        </div>
    );
}