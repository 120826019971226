import { useEffect, useState } from "react";
import { useObjectState } from "../hooks";
import { useApi, useAuth } from "../security";
import { Roles } from "../types";
import Avatar from "./Avatar";

export default function AccountDetail(props) {
    const api = useApi();
    const user = props.user || {};
    const { auth, access } = useAuth();

    const [firstName, setFirstName] = useObjectState(user, 'firstName');
    const [lastName, setLastName] = useObjectState(user, 'lastName');
    const [email, setEmail] = useObjectState(user, 'email');
    const [website, setWebiste] = useObjectState(user, 'websiteUrl');
    const [tags, setTags] = useObjectState(user, 'tags');

    const [image, setImage] = useState(user.image);

    function onImageChanged(file) {
        user.image = file;
    }

    useEffect(() => {
        if (user.image != null) {
            setImage(api.users.getAvatarUrl(user.id));
            console.log(user.image)
        }
        console.log(user.image)
    }, []);

    return (<>
        <div className="mt-3">
            <div className="row">
                <div className="col-3">
                    <Avatar image={image} onImageChanged={onImageChanged} />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <label>First name</label>
                            <input type="text" className="form-control" name='firstName' value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
                        </div>
                        <div className="col">
                            <label>Last name</label>
                            <input type="text" className="form-control" name='lastName' value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Email address</label>
                            <input type="email" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Tags/keywords/specialty tools (i.e. Mako specialist, etc.)</label>
                            <input type="text" className="form-control" name='tags' maxLength={100} value={tags} onChange={(e) => setTags(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Website address</label>
                            <div className="input-group">
                                <span className="input-group-text">https://</span>
                                <input type="email" className="form-control" name='website' value={website} onChange={(e) => setWebiste(e.currentTarget.value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}