import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppointmentReservation from '../components/AppointmentReservation';
import { useApi, useAuth } from '../security';
import BusyIndicator from '../components/BusyIndicator';
import DoctorTile from '../components/DoctorTile';
import Modal from '../components/Modal';
import { getDatePart, getTimePart, toEndOfDay, toStartOfDay } from '../helpers';
import { useTitle } from '../hooks';
import IdentityModal from '../identity/IdentityModal';
import Login from '../identity/Login';
import { AppointmentReservationStatus, Timespan } from '../types';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, LinkAuthenticationElement, useStripe, useElements } from "@stripe/react-stripe-js";
import './Reserve.css';
import PasswordRequirements from '../identity/PasswordRequirements';
import Card from '../components/Card';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const ReservationState = {
    patientType: 0,
    reason: 1,
    terms: 2,
    payment: 3,
    count: 4
};

function PatientType(props) {
    const doctor = props.doctor || {};
    const appointment = props.appointment || {};
    const dt = new Date(appointment?.appointmentDate);

    return (<Card headerText="Just a few more details">
        <p>Reserve an appointment with <span className='fw-bold'>Dr. {doctor.fullName}</span> on <span className='fw-bold'>{dt.toString()}</span>.</p>
        <p className='tw-pt-5 tw-text-xl'>I am a...</p>
        <div className='tw-flex tw-flex-col sm:tw-flex-row tw-justify-center tw-items-center'>
            <button className='btn btn-primary btn-lg m-2' onClick={e => props.onNext('new')}>New Patient</button>
            <button className='btn btn-primary btn-lg m-2' onClick={e => props.onNext('existing')}>Established Patient</button>
        </div>
    </Card>);
}

function Reason(props) {
    const [reason, setReason] = useState('');
    return (<Card>
        <p className='tw-pb-3'>Any information for the specialist of scheduling team? (optional)</p>
        <div className='tw-w-1/2'>
            <textarea type='text' rows="3" className='form-control' value={reason} onChange={e => setReason(e.target.value)} />
        </div>
        <div className='tw-flex tw-justify-center tw-items-center tw-pt-3'>
            <button className='btn btn-secondary btn-lg m-2' onClick={e => props.onPrevious()}>Back</button>
            <button className='btn btn-primary btn-lg m-2 me-auto' onClick={e => props.onNext(reason)}>Next</button>
        </div>
    </Card>);
}

function Terms(props) {
    return (<Card headerText="Legal Terms">
        <p className='tw-py-3'>Lorem ipsum odor amet, consectetuer adipiscing elit. Mollis ac lectus efficitur ad morbi venenatis hendrerit sociosqu sagittis. Senectus porttitor adipiscing torquent duis interdum. Suscipit tortor condimentum posuere tellus praesent fames luctus. Imperdiet euismod erat facilisis taciti faucibus sodales parturient tempor. Nascetur convallis neque nibh litora viverra massa conubia vivamus. Nam dui quisque tincidunt nibh hendrerit dapibus felis tincidunt. Morbi habitasse sollicitudin quisque finibus porta at vulputate velit volutpat.

            Curae ullamcorper hendrerit et maecenas, imperdiet eu interdum. Sociosqu ullamcorper tempus, curae ultrices quis volutpat. Aliquet sed cras sed mattis non aenean praesent taciti. Turpis auctor class porta in lectus tempor ultrices justo? Gravida egestas elit commodo; nec tempor conubia. Praesent ac diam iaculis hendrerit lectus hendrerit leo mollis maecenas. Dolor lacinia leo placerat posuere venenatis non.

            Cursus litora placerat quam gravida dolor orci potenti? Elit eros neque platea nunc habitant. Augue pellentesque aptent vehicula dictumst ridiculus in odio proin tellus. Felis ex justo interdum aptent; elit rutrum. Ex bibendum volutpat velit senectus lorem. Integer condimentum libero aliquet neque quis rutrum. Curabitur litora tempor laoreet risus ex. Varius duis sociosqu ultrices hac nascetur sociosqu. Nostra consequat id efficitur quam mollis.

            Ipsum sapien lobortis orci habitant maecenas tristique scelerisque porttitor. Imperdiet rutrum consectetur aliquet consectetur ullamcorper class amet velit. Tortor montes gravida lacus aenean purus. Ligula dis vestibulum pretium magnis montes vivamus posuere hac metus. Laoreet gravida vestibulum conubia rhoncus quam risus. Sit felis id ridiculus cursus, potenti id. Porttitor dis ligula at sollicitudin cursus per cursus libero cras? Consectetur sociosqu lacinia faucibus enim eget fames. Tristique quisque consequat fusce porttitor laoreet. Pretium tincidunt tristique maecenas ullamcorper vulputate.

            Congue libero metus pulvinar; ultrices ad dapibus bibendum erat rutrum. Lorem feugiat integer fames iaculis lacinia; suspendisse lacus. Augue neque cursus pretium lectus diam vulputate. Feugiat laoreet blandit malesuada habitant elit, justo ac finibus. Ultrices dignissim felis primis varius faucibus nunc. Senectus ornare morbi ridiculus mus platea rhoncus elementum dictum parturient. Nunc ultrices lacus potenti eget cras inceptos. Ultrices ante egestas auctor habitasse bibendum himenaeos neque. Sed adipiscing scelerisque tempus curae in massa.
        </p>
        <div className='tw-flex tw-items-center tw-justify-center'>
            <button className='btn btn-secondary btn-lg m-2' onClick={e => props.onPrevious()}>Back</button>
            <button className='btn btn-primary btn-lg m-2 me-auto' onClick={e => props.onNext()}>I Agree</button>
        </div>
    </Card>)
}

function Checkout(props) {
    const api = useApi();
    const { auth } = useAuth();
    const appt = props.appointment || {};
    const doctor = props.doctor || {};
    const stripe = useStripe();
    const elements = useElements();
    const [isBusy, setIsBusy] = useState(false);
    const [msg, setMsg] = useState();
    const [passwordRules, setPasswordRules] = useState('');
    const [timezones, setTimezones] = useState([]);

    const [shouldCreateAcct, setShouldCreateAcct] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState(auth?.email || '');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [timezone, setTimezone] = useState('');

    const showError = (message) => {
        setMsg(message);
        setIsBusy(false);
    }

    const process = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        if (!firstName) {
            showError('First name is required.');
            return;
        }
        if (!lastName) {
            showError('Last name is required.');
            return;
        }

        setIsBusy(true);

        if (shouldCreateAcct) {
            if (!email) {
                showError('Email address is required.');
                return;
            }
            if (!password) {
                showError('Password is required.');
                return;
            }
            if (!timezone) {
                showError('Timezone is required.');
                return;
            }
        }

        const response = await api.appointments.sessionUpdate(appt.id, {
            email,
            phone,
            firstName,
            lastName,
            password,
            timezone
        });
        if (response.error) {
            showError(response.error);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/reserve/confirm/' + appt.id
            }
        });

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMsg(error.message);
        } else {
            setMsg('An unexpected error occurred while atempting to process your pyament. Please try again later.');
        }

        setIsBusy(false);
    }

    useEffect(() => {
        api.common.timezones().then(r => setTimezones(r.data));
        api.identity.passwordRules().then(r => setPasswordRules(r.data));
    }, []);

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (<Card>
        <form onSubmit={process} className="tw-w-full">
            {msg && <div className='alert alert-danger'>{msg}</div>}
            <div>
                <h3>Reservation details</h3>
                <p className='fw-bold'>Dr. {doctor.fullName}</p>
                <p className='fw-bold'>{new Date(appt.appointmentDate).toString()}</p>
            </div>
            <div className='tw-flex tw-flex-col'>
                <h3>Your details</h3>
                <p className='text-muted tw-my-2'><span className='text-danger'>*</span> required fields</p>
                <div className='row form-group'>
                    <div className='col'>
                        <label>First name <span className='text-danger'>*</span></label>
                        <input type='text' autoComplete='given-name' className='form-control' value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className='col'>
                        <label>Last name <span className='text-danger'>*</span></label>
                        <input type='text' autoComplete='family-name' className='form-control' value={lastName} onChange={e => setLastName(e.target.value)} />
                    </div>
                </div>
                <div className='row form-group mt-2'>
                    <div className='col'>
                        <label>Email address {shouldCreateAcct && <span className='text-danger'>*</span>}</label>
                        <input type='email' autoComplete='email' className='form-control' value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className='col'>
                        <label>Cell phone number</label>
                        <input type='text' autoComplete='mobile tel' className='form-control' value={phone} onChange={e => setPhone(e.target.value)} />
                    </div>
                </div>
                <div className='mt-2'>
                    <div className='col form-check'>
                        <input type='checkbox' autoComplete='email' className='form-check-input' value={shouldCreateAcct} onChange={e => setShouldCreateAcct(!shouldCreateAcct)} />
                        <label className='form-check-label'>Create an account (optional)</label>
                    </div>
                </div>
                {shouldCreateAcct &&
                    <>
                        <div className='row form-group mt-2'>
                            <div className='col'>
                                <label>Password <span className='text-danger'>*</span></label>
                                <input type='password' autoComplete='new-password' className='form-control' value={password} onChange={e => setPassword(e.target.value)} />
                            </div>
                            <div className='col'>
                                <label>Timezone <span className='text-danger'>*</span></label>
                                <select className="form-select" name='timezone' value={timezone} onChange={(e) => setTimezone(e.currentTarget.value)}>
                                    <option value=''></option>
                                    {(timezones || []).map((s, i) => <option key={i} value={s.id}>{s.id}</option>)}
                                </select>
                            </div>
                            <PasswordRequirements rules={passwordRules} />
                        </div>
                    </>}
            </div>
            <div className='mt-4'>
                <h3>Payment details</h3>
                <PaymentElement options={paymentElementOptions} />
            </div>
            <div className='mt-3'>
                <button disabled={isBusy || !stripe || !elements} className="btn btn-success btn-lg">
                    <span>{isBusy ? <span className='spinner-border spinner-border-sm'></span> : <span>Pay now</span>}</span>
                </button>
            </div>
        </form>
    </Card>);
}

function Payment(props) {
    const api = useApi();
    const appt = props.appointment || {};
    const [token, setToken] = useState();
    const [isBusy, setIsBusy] = useState(true);
    const [stripeOptions, setStripeOptions] = useState();

    useEffect(() => {
        api.appointments.session(appt)
            .then(r => {
                console.log('payment intent:', r.data)
                setToken(r.data.token);
                setIsBusy(false);
                setStripeOptions({
                    appearance: {
                        theme: 'stripe'
                    },
                    clientSecret: r.data.token
                });
            });
    }, []);

    if (isBusy) {
        return <BusyIndicator />;
    }

    return (<div>
        {/* <div className=''>
            <button className='btn btn-success btn-lg' onClick={props.onLogin}>Sign in to book faster</button>
        </div> */}
        <Elements options={stripeOptions} stripe={stripePromise}>
            <Checkout api={api} appointment={appt} doctor={props.doctor} />
        </Elements>
    </div>);
}

export default function Reserve() {
    useTitle('Make a reservation');

    const api = useApi();
    // const nav = useNavigate();

    const { state } = useLocation();
    const doctor = state.doctor;
    const appointment = state.appointment;

    const dt = new Date(appointment?.appointmentDate);

    const [current, setCurrent] = useState(ReservationState.patientType);
    const [error, setError] = useState();
    const [isBusy, setIsBusy] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [patientType, setPatientType] = useState();
    const [reason, setReason] = useState();
    const [paymentDetails, setPaymentDetails] = useState();

    function updateContent(index) {
        if (index < 0 || index >= ReservationState.count) {
            return;
        }
        setCurrent(index);
    }

    function next() {
        switch (current) {
            case ReservationState.patientType:
                setPatientType(arguments[0]);
                break;
            case ReservationState.reason:
                setReason(arguments[0]);
                break;
            case ReservationState.payment:
                setPaymentDetails(arguments[0]);
                break;
            case ReservationState.confirmation:
                setIsBusy(true);
                complete();
                return;
        }

        updateContent(current + 1);
    }
    function previous() {
        updateContent(current - 1);
    }

    function login() {
        setIsLoggingIn(true);
    }

    function authenticated() {
        console.log('authenticated: ', arguments);
    }

    function complete() {
        const request = {
            payment: paymentDetails
        };
    }

    // useEffect(() =>{
    //     api.common.paymentTypes()
    //         .then(r => setPaymentTypes(r.data));
    // },[]);

    if (isBusy) {
        return <BusyIndicator />;
    }

    if (isLoggingIn) {
        return (<Login onAuthenticated={authenticated} />);
    }

    return (<>
        {error && <div className="alert alert-danger">{error}</div>}

        {current === ReservationState.patientType && <PatientType doctor={doctor} appointment={appointment} onNext={next} />}
        {current === ReservationState.reason && <Reason doctor={doctor} appointment={appointment} onNext={next} onPrevious={previous} />}
        {current === ReservationState.terms && <Terms doctor={doctor} appointment={appointment} onNext={next} onPrevious={previous} />}
        {current === ReservationState.payment && <Payment api={api} doctor={doctor} appointment={appointment} onNext={next} onPrevious={previous} onLogin={login} />}
    </>);

    //return <AppointmentReservation doctor={state.doctor} appointment={state.appointment}/>
}