import { useState } from "react";
import { useAuth } from "../security";
import { Roles } from "../types";
import ResultBox from "./ResultBox";
import ResultBoxRow from "./ResultBoxRow";

function SearchResultRow(props) {
    const result = props.result;
    const selectionLabel = props.selectionLabel || 'Edit';
    const dt = new Date(result.dateAdded);

    return (<ResultBox headerText={result?.name}>
        <ResultBoxRow name="Address:" value={result?.city + ", " + result?.territory + " " + result?.postalCode1} />
        <button className="btn btn-primary" onClick={e => props?.onSelected(result)}>{selectionLabel}</button>
    </ResultBox>)
}

function SearchResults(props) {
    const results = props.results || { items: [], totalRecords: 0, nextPage: null };

    if (results?.length === 0) {
        return (<></>);
    }

    return (<>
        <hr />
        <div className="tw-flex tw-flex-wrap tw-justify-center">
            {(results?.items || []).map((r, i) => <SearchResultRow key={i} result={r} {...props} onSelected={props?.onSelected} />)}
        </div>
    </>);
}

export default function SearchOrganizations(props) {
    const territories = props.territories;
    const results = props.results

    const { auth } = useAuth();

    const [selectedTerritory, setSelectedTerritory] = useState(props.criteria?.territory || '');
    const [query, setQuery] = useState(props.criteria?.query || '');

    function search() {
        props?.onSearch({
            territory: selectedTerritory,
            query
        });
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <label htmlFor="state">State/Territory</label>
                    <select id="state" className="form-select" value={selectedTerritory} onChange={e => setSelectedTerritory(e.target.value)}>
                        <option></option>
                        {(territories || []).map((t, i) => <option key={i} value={t.code}>{t.name}</option>)}
                    </select>
                </div>
                <div className="col">
                    <label htmlFor="query">Group Name <span className="text-secondary">(optional)</span></label>
                    <input type="text" className="form-control" id="query" name="query" value={query} onChange={e => setQuery(e.target.value)} />
                </div>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end tw-my-4">
                {props.allowCancel && (
                    <button className="btn btn-outline-danger me-2" onClick={props?.onCancel}>Cancel</button>
                )}
                {Roles.isSchedulerOrInternalUser(auth.roles) && (
                    <button className="btn btn-success me-2" onClick={props?.onAdd}>Add New Medical Group</button>
                )}
                <button className="btn btn-primary" onClick={search}>Find Medical Groups</button>
            </div>
            <SearchResults results={results} {...props} onSelected={props?.onSelected} />
        </>
    );
}