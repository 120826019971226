import React, { useState } from 'react';
import { Roles } from "../types";
import { useAuth } from "../security";
import NavItem from './NavItem';

function NotSignedIn() {
    return (
        <>
            <NavItem link='/register/doctor' text='List your Practice' css="btn btn-secondary tw-mx-2" />
            <NavItem link='/identity/login' text='Log in' css="btn btn-secondary tw-mx-2" />
            <NavItem link='/identity/signup' text='Sign up' css='btn btn-primary tw-mx-2' />
        </>
    );
}

function SignedIn(props) {
    const auth = props.auth;
    console.log("role: ", auth)
    const links = [];

    if (Roles.isInRole(auth.roles, Roles.internalUser, Roles.internalAdmin, Roles.systemAdmin)) {
        links.push({ link: '/users/dashboard', text: 'Dashboard' });
    }
    if (Roles.isScheduler(auth.roles)) {
        links.push({ link: '/users/list', text: 'Doctors' });
    }

    links.push({ link: '/users', text: 'Account Details' });

    return (
        <>
            {links.map((l, i) => <NavItem key={i} link={l.link} text={l.text} css="btn btn-secondary tw-mx-2" />)}
            <NavItem link='/identity/logout' text='Log out' css="btn btn-secondary tw-mx-2" />
        </>
    );
}

export default function IdentityStatus() {
    const { auth } = useAuth();
    return auth ? <SignedIn auth={auth} /> : <NotSignedIn />;
}