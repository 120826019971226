import { useState } from "react";
import { useApi, useAuth } from "../security";
import Alert from "../components/Alert";
import BusyIndicator from "../components/BusyIndicator";
import { useNavigate } from "react-router-dom";
import Card from "../components/Card";

export default function ChangePasswordFirstLogin(props) {
    const api = useApi();
    const { setAuth } = useAuth();
    const nav = useNavigate();

    const [error, setError] = useState();
    const [isBusy, setIsBusy] = useState(false);
    const [email, setEmail] = useState(props.email || '');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    function submit(e) {
        e.preventDefault();

        if (!email) {
            setError('Email address is required.');
            return;
        }
        if (!password) {
            setError('Current password is required.');
            return;
        }
        if (!newPassword) {
            setError('New password is required.');
            return;
        }
        if (!newPasswordConfirm) {
            setError('Confirm new password is required.');
            return;
        }
        if (newPassword !== newPasswordConfirm) {
            setError('New password and confirm new password values do not match. Please re-type your new password exactly.');
            return;
        }

        setIsBusy(true);
        setError(null);
        api.identity.changePassword(email, password, newPassword, newPasswordConfirm)
            .then(r => {
                setIsBusy(false);
                if (r.data.success) {
                    api.identity.signout();
                    nav('/identity/login');
                } else {
                    setError(r.data.error);
                }
            }).catch(e => {
                setIsBusy(false);
                setError(e.toString());
            });
    }

    if (isBusy) {
        return (<BusyIndicator />)
    }

    return (<Card className='tw-flex tw-items-center'>
        <Alert error={error} />
        <p className="text-center">To enhance the security related to login credentials, you are required to change your password.</p>
        <div className="tw-flex tw-w-full tw-justify-center tw-mt-5">
            <div className="tw-flex tw-flex-col tw-w-3/5">
                <form onSubmit={submit}>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input type="email" name="email" autoComplete="email" className="form-control" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label>Current Password</label>
                        <input type="password" name="password" className="form-control" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <input type="password" name="new_password" className="form-control" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Confirm New Password</label>
                        <input type="password" name="new_pasword_confirm" className="form-control" value={newPasswordConfirm} onChange={e => setNewPasswordConfirm(e.target.value)} />
                    </div>
                    <div className="form-group mt-4 d-grid gap-2">
                        <button type="submit" className="btn btn-lg btn-primary">Change password</button>
                    </div>
                </form>
            </div>
        </div>
    </Card>);
}