import { useEffect, useState } from "react";
import { getHourRange, getStartOfWeek, groupBy, sortByStartTime, addDays } from "../helpers";
import back from './arrow_back.png';
import forward from './arrow_forward.png';
import { AppointmentReservationStatus, AppointmentStatus } from "../types";

function DayHeader(props) {
    const now = new Date();
    const today = (now.getFullYear() === props.date.getFullYear() && now.getMonth() === props.date.getMonth() && now.getDate() === props.date.getDate())
        ? 'today' : '';

    return (
        <div>
            <div className="row">
                <div className="col text-center appt-calendar-header-day gray">
                    <span className={today}>{props.day}</span>
                </div>
            </div>
            <div className="row">
                <div className="col text-center appt-calendar-header-date gray">
                    <div className={today}>{props.date.getDate()}</div>
                </div>
            </div>
        </div>
    );
}

function DayItem(props) {
    const item = props.item || { startTime: '00:00:00', endTime: '00:00:00', duration: '00:00:00' };
    const parts = item.startTime.split(':');
    const value = parseInt(parts[0]) + ':' + parts[1];
    return (<div className="appt-calendar-time">
        {value}
    </div>);
    // const minute = item.startTime.split(':')[1];

    // const css = 'appt-calendar-item' + (item.status !== AppointmentStatus.open ? ' appt-calendar-item-scheduled' : '');

    // const content = (<div className={css}>{item.duration.split(':')[1]} mins</div>);

    // const contents =[<div></div>,<div></div>,<div></div>,<div></div>];

    // if (minute === '15'){
    //     contents[1] = content;
    // }else if (minute === '30'){
    //     contents[2] = content;
    // }else if (minute === '45'){
    //     contents[3] = content;
    // }else{
    //     contents[0] = content;
    // }

    // return (
    //     <div className="appt-calender-times">
    //         <div className="row time-1">
    //             <div className="col">{contents[0]}</div>
    //         </div>
    //         <div className="row time-2">
    //             <div className="col">{contents[1]}</div>
    //         </div>
    //         <div className="row time-3">
    //             <div className="col">{contents[2]}</div>
    //         </div>
    //         <div className="row time-4">
    //             <div className="col">{contents[3]}</div>
    //         </div>
    //     </div>
    // );
}

function CalendarHeader(props) {
    const dt = new Date(props.date);

    const days = [
        { label: 'SUN', index: 0 },
        { label: 'MON', index: 1 },
        { label: 'TUE', index: 2 },
        { label: 'WED', index: 3 },
        { label: 'THU', index: 4 },
        { label: 'FRI', index: 5 },
        { label: 'SAT', index: 6 },
    ];

    return (
        <div className="row appt-calendar-header">
            <div className="col">

            </div>
            {days.map((d, i) => <div key={i} className="col appt-calendar-header-item"><DayHeader day={d.label} date={addDays(dt, d.index)} /></div>)}
        </div>
    );
}

function CalendarRow(props) {
    const hour = props.hour || 8;
    const days = [0, 1, 2, 3, 4, 5, 6];
    const items = sortByStartTime([...props.items] || []);

    function getItems(day) {
        return items.filter(item => new Date(item?.appointmentDate).getDay() === day
            && parseInt(item?.startTime?.split(':')[0] || '-1') === parseInt(hour));
    }

    return (
        <div className="row appt-calendar-row">
            <div className="col appt-calendar-row-label">
                {(hour > 12 ? hour - 12 : hour)} {(hour >= 12 ? 'PM' : 'AM')}
            </div>
            {days.map((d, i) => <div key={i} className="col appt-calendar-row-item">{getItems(d).map((o, i) => <DayItem key={i} item={o} />)}</div>)}
        </div>
    );
}

export default function AppointmentCalendar(props) {
    const results = props.results || [];
    const items = results?.items || [];
    const [now, setNow] = useState(new Date());

    const hours = getHourRange(([...items] || []).map((o, i) => o));

    function getItems(hour) {
        return items?.filter(item => parseInt(item?.startTime?.split(':')[0] || '-1') === parseInt(hour));
    }

    function move(next) {
        const dt = addDays(now, next ? 7 : -7);
        updateDate(dt);
    }

    function today() {
        const dt = getStartOfWeek(new Date());
        updateDate(dt);
    }

    function updateDate(dt) {
        setNow(dt);
        props?.onDateChange(dt);
    }

    useEffect(() => {
        today();
    }, []);

    return (
        <div className="appt-calendar">
            <div className="row mb-4">
                <div className="col">
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-outline-primary" onClick={today}>Today</button>
                    </div>
                </div>
                <div className="col-1">
                    <button className="btn month-nav" onClick={() => move(false)}><img src={back} className="btnCalendar" alt="back" /></button>
                </div>
                <div className="col-1">
                    <button className="btn month-nav" onClick={() => move(true)}><img src={forward} className="btnCalendar" alt="forward" /></button>
                </div>
                <div className="col month">
                    {now.toLocaleString('en-us', { month: 'long' })} {now.getFullYear()}
                </div>
            </div>

            <CalendarHeader date={now} />

            {hours.map((h, i) => <CalendarRow key={i} hour={h} items={getItems(h)} />)}
        </div>
    );
}