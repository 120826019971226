import {Navigate, Outlet} from 'react-router-dom';
import { useAuth } from "../security";

const AuthorizeRoutes = ({requiredRoles})=>{
    const {auth} = useAuth();

    if ((requiredRoles && requiredRoles.length > 0)
        && (!auth.roles || !auth.roles.some(r => requiredRoles.includes(r)))){
            return (<Navigate to='/unauthorized'/>);
        }

    return (auth ? <Outlet/> : <Navigate to='/identity/login'/>);
};
export default AuthorizeRoutes;