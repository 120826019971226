import process from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";

class ServiceBase{
    constructor(api, path){
        this.api = api;
        this.baseUrl = process.env.REACT_APP_API_URI + path;
    }

    get(url, data){
        return this.send(this.options(url, 'GET', data));
    }
    post(url, data){
        return this.send(this.options(url, 'POST', data));
    }
    put(url, data){
        return this.send(this.options(url, 'PUT', data));
    }
    del(url, data){
        return this.send(this.options(url, 'DELETE', data));
    }

    upload(url, file, appendFormProps){
        const form = new FormData();
        form.append(file.name, file);
        appendFormProps?.(form);
        //return axios.post(this.baseUrl + url, form);
        let opts = this.options(url, 'POST', form);
        opts.headers['Accept'] = '*/*';
        delete opts.headers['Content-Type'];
        //opts.headers['Content-Type'] = 'multipart/form-data';
        return this.send(opts);
    }

    download(url){
        let opts = this.options(url, 'GET', null);
        opts.responseType = 'blob';
        return this.send(opts);
    }

    options(url, method, data){
        let opts = {
            method:method,
            url:this.baseUrl + url,
            data:data,
            headers:{
                'Accept': 'applicaiton/json',
                'Content-Type': 'application/json',
            }
          };

        if (this.api.authenticated()){
            opts.headers['Authorization'] = 'Bearer ' + this.api.auth?.accessToken?.value;
        }
        return opts;
    }

    send(options){
        console.log('API_SERVICE['+this.api.id+']: ['+options.method+'] ' + options.url);
        console.log('  HEADERS:', options.headers);
        //console.log(options.data);
        return axios(options);
    }
}

class CommonService extends ServiceBase{
    constructor(api){
        super(api, '/common');
        this.store={
            specialties:[],
            roles:[],
            territories:[],
            timezones:[],
            paymentTypes:[]
        };
    }

    getOrLoad(url, prop){
        const data = this.store[prop] || [];
        if (data.length > 0){
            return Promise.resolve({data});
        }
        let resolve,reject;
        const result = new Promise(function(res, rej){resolve = res; reject = rej});
        this.get(url)
            .then(r => {
                this.store[prop] = r.data;
                resolve(r);
            }).catch(e => reject(e))
        return result;
    }

    load(){
        let resolve,reject;
        const result = new Promise(function(res, rej){resolve = res; reject = rej});
        this.get('/')
            .then(r =>{
                this.store = r.data;
                resolve();
            }).catch(e => reject(e));
        return result;
    }

    specialties(){
        return this.getOrLoad('/specialties', 'specialties');
    }

    roles(){
        return this.getOrLoad('/roles', 'roles');
    }

    territories(){
        return this.getOrLoad('/territories', 'territories');
    }

    timezones(){
        return this.getOrLoad('/timezones', 'timezones');
    }

    paymentTypes(){
        return this.getOrLoad('/paymenttypes', 'paymenttypes');
    }
}

class AppointmentsService extends ServiceBase{
    constructor(api){
        super(api, '/appointments');
    }

    search(criteria){
        return this.post('/search', criteria);
    }

    session(appointment){
        return this.post('/session',appointment);
    }

    sessionUpdate(id, data){
        return this.post('/session/update/' + id, data);
    }

    getAppointment(id){
        return this.get('/' + id);
    }

    resend(id){
        return this.post('/resend/' + id);
    }

    data(id){
        return this.get('/data/' + id);
    }

    repeatTypes(){
        return this.get('/repeattypes');
    }

    durations(){
        return this.get('/durations');
    }

    schedule(criteria){
        return this.post('/schedule/', criteria);
    }

    config(userId, organizationId){
        return this.get('/configuration/' + userId + '/' + organizationId);
    }

    reset(userId, organizationId){
        return this.get('/reset/' + userId + '/' + organizationId);
    }

    update(userId, organizationId, id, action, data){
        return this.post('/configuration', {
            id,
            userId,
            organizationId,
            action,
            ...data
        });
    }
}

class UsersAccessService extends ServiceBase{
    constructor(api, path){
        super(api, path + '/access');
    }

    load(id){
        return this.get('/' + id);
    }
    update(data){
        return this.post('/', data);
    }
    remove(primaryUserId, linkedUserId){
        return this.del('/' + primaryUserId + '/' + linkedUserId);
    }
}

class UsersOrganizationsService extends ServiceBase{
    constructor(api, path){
        super(api, path + '/organizations');
    }

    list(id){
        return this.get('/' + id);
    }

    add(id, organization){
        return this.put('/' + id, organization);
    }

    update(id, organizations){
        return this.post('/', { userId:id, organizations});
    }
}

class UsersService extends ServiceBase{
    constructor(api){
        super(api, '/users');
        this.access = new UsersAccessService(api, '/users');
        this.organizations = new UsersOrganizationsService(api, '/users');
    }

    tasks(){
        return this.get('/tasks');
    }

    details(id){
        return this.get('/' + id);
    }

    update(details){
        return this.post('/update', details);
    }

    add(data){
        return this.post('/add', data);
    }

    search(criteria){
        return this.post('/search',criteria);
    }

    status(id){
        return this.get('/status/' + id);
    }

    activate(id){
        return this.put('/activate/' + id);
    }

    list(id){
        return this.get('/list/' + id);
    }

    getAvatar(id){
        return this.download('/avatar/' + id);
    }
    getAvatarUrl(id){
        return this.baseUrl + '/avatar/' + id;
    }

    saveAvatar(id, file){
        return this.upload('/avatar', file, form =>{
            form.append('id', id);
        });
    }
}

class OrganizationService extends ServiceBase{
    constructor(api){
        super(api, '/organizations');
    }

    search(criteria){
        return this.post('/search', criteria);
    }

    load(id){
        return this.get('/' + id);
    }

    locations(id){
        return this.get('/locations/' + id);
    }

    save(organization){
        return this.post('/', organization);
    }
}

class RegistrationService extends ServiceBase{
    constructor(api, path){
        super(api, path + '/register');
    }

    doctor(data){
        return this.post('/doctor', data);
    }

    validate(token){
        return this.post('/validate', {token});
    }
}

class IdentityService extends ServiceBase{
    constructor(api){
        const path = '/identity';
        super(api, path);
        this.registration = new RegistrationService(api, path);
    }

    authenticate(email, password){
        return this.post('/authenticate', {email, password});
    }

    authorize(provider, token){
        return this.post('/authorize', {provider, token});
    }

    confirm(email, code){
        return this.post('/confirm', {email, code});
    }

    resend(email){
        return this.post('/resend', {email});
    }

    forgotPassword(email){
        return this.post('/forgotpassword', {email});
    }

    changePassword(email, password, newPassword, newPasswordConfirm){
        return this.post('/changepassword',{email, password, newPassword, newPasswordConfirm});
    }

    resetPassword(email, code, password, passwordConfirm){
        return this.post('/resetpassword',{email,code,password,passwordConfirm});
    }

    signout(){
        return this.post('/signout');
    }

    // update(data){
    //     return this.post('/update', data);
    // }

    photo(file){
        console.log(file);
        return this.upload('/photo', file);
    }

    passwordRules(){
        return this.get('/passwordrules');
    }
}

class ApiService{
    static _id = 0;
    constructor(){
        this.id = 'API-' + ApiService._id++;
        this.auth = JSON.parse(localStorage.getItem('auth') || 'null');
        this.common = new CommonService(this);
        this.appointments = new AppointmentsService(this);
        this.identity = new IdentityService(this);
        this.users = new UsersService(this);
        this.organizations = new OrganizationService(this);
        console.log('Initializing API SERVICE', this.id);
    }
    
    authenticated(){
        if (this.auth === null || this.auth === undefined){
            console.log('API_SERVICE['+this.id+']: Auth token is null.');
            return false;
        }
        let now = new Date();
        if (now < this.auth.expires){
            console.log('API_SERVICE['+this.id+']: Auth token expired.');
            return false;
        }
        return this.auth.accessToken !== null && this.auth.accessToken !== undefined;
    }
    setToken(value){
        console.log('API_SERVICE['+this.id+']: Setting auth:', value);
        this.auth = value;
        if (this.auth !== null && this.auth !== undefined){
            const decoded = jwt_decode(this.auth.accessToken?.value);
            for (const [key,value] of Object.entries(decoded)){
                this.auth[key]=value;
            }
            this.auth['id'] = decoded['sub'];
            if (this.auth.role && !Array.isArray(this.auth.role)){
                this.auth.role = [this.auth.role];
            }
            this.auth.roles = this.auth.role;
        }
        console.log('API_SERVICE['+this.id+']: Storing auth:', this.auth);
        localStorage.setItem('auth', JSON.stringify(this.auth));
    }
    signout(){
        const self = this;
        this.identity.signout()
            .then(() => self.clearAuth())
            .catch(() => self.clearAuth());
        
    }
    clearAuth(){
        this.auth = null;
        localStorage.setItem('auth', null);
    }
}
ApiService.instance = new ApiService();
export default ApiService;