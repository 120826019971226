import { useEffect, useState } from "react";
import { useApi } from "../security";
import { Roles } from "../types";

function RoleButton(props){
    const role = props.role || '';
    const id = 'btn_' + role;
    const [checked, setChecked] = useState(props.checked);

    function checkChanged(e){
        const nextState = !checked;
        setChecked(nextState);
        props?.checkChanged?.(role, nextState);
    }

    return (<div className='d-inline ms-2 me-2'>
        <input type="checkbox" className="btn-check" id={id} autoComplete="off" checked={checked} onChange={checkChanged}/>
        <label className="btn btn-outline-primary" htmlFor={id}>{role}</label>
    </div>);
}

export default function AccountTypeSelector(props){
    const api = useApi();
    const user = props.user || {};
    const selected = props.selected || [];

    const [roles, setRoles] = useState([]);

    useEffect(() =>{
        if (roles.length === 0){
            api.common.roles()
                .then(r =>{
                    setRoles(r.data);
                });
        }
    },[]);

    return (
        <>
        <div className="row">
            <div className="col">
                <div className="mb-2">Account Type</div>
                {(roles || []).map((role,idx)=>{
                    const css = 'btn ms-2 me-2 ' + (selected.includes(role) ? 'btn-primary' : 'btn-outline-primary');
                    return (
                        <button key={idx} className={css} disabled={true}>{Roles.getName(role)}</button>
                    );
                })}
            </div>
        </div>
        </>
    );
    
    // return (
    //     <>
    //     <div className="row">
    //         <div className="col">
    //             <div className="mb-2">Account Type</div>
    //             {(roles || []).map((r,i) => <RoleButton key={i} role={r} checked={selected.includes(r)} checkChanged={props?.checkChanged}/>)}
    //         </div>
    //     </div>
    //     </>
    // );
}