import { Tab, Tabs } from "./Navigation";

export default function Earnings(props){
    const api = props.api;
    const user = props.user;
    const auth = props.auth;
    const scopes = props.scopes || [];

    return (
        <Tabs id="earnings">
            <Tab title="Recent Earnings">

            </Tab>
            <Tab title="Previous Earnings">

            </Tab>
        </Tabs>
    );
}