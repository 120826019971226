import { useEffect, useState } from "react";
import { useApi } from "../security";

function Task(props){
    const task = props.task || {key:'',value:false};
    const css = 'col alert ' + (task.value ? 'alert-success' : 'alert-danger');
    const label = (task.value ? 'Complete' : 'Not Complete');
    return (<div className="row">
        <div className="col alert">{task.key}</div>
        <div className={css}>{label}</div>
    </div>);
}

export default function OnBoardingStatus(props){
    const user = props.user;
    const api = useApi();

    const [status, setStatus] = useState({isValid:false,tasks:{}});
    const [tasks, setTasks] = useState([]);

    useEffect(() =>{
        api.users.status(user.id)
            .then(r =>{
                console.log('ONBOARDING: ', r.data);
                setStatus(r.data);
                const newTasks = [];
                for (let p in r.data.tasks){
                    newTasks.push({key:p, value:r.data.tasks[p]});
                }
                setTasks(newTasks);
            }).catch(e =>{

            });
    },[]);

    return (<>
    <div className="row">
        <div className="col">
            {(tasks || []).map((t,i) => (<Task key={i} task={t}/>))}
        </div>
    </div>
    </>);
} 