import { useEffect, useState } from "react";
import { useApi } from "../security";

export default function AccountStatus(props){
    const api = useApi();
    const user = props.user || {};

    const [checked, setChecked] = useState(user.isActive);
    const [status, setStatus] = useState('');

    function activate(){
        api.users.activate(user.id)
            .then(r =>{ 
                user.isActive = true;
                updateState(user.isActive);
            }).catch(e => updateState(user.isActive));
    }

    function updateState(active){
        setStatus(active ? 'active' : 'not activated');
        setChecked(active);
    }

    useEffect(() =>{
        updateState(user.isActive);
    },[]);
 
    return (<div>
        <div className="row">
            <div className="col-auto alert">
                User is <span className={(user.isActive ? 'fw-bold alert alert-success' : 'fw-bold alert alert-danger')}>{status}</span>
            </div>
        </div>
        {!user.isActive
            && (
        <div className="row">
            <div className="col">
                <button className="btn btn-success" onClick={activate}>Activate User</button>
            </div>
        </div>)}
    </div>);
}