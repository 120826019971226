import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi, useAuth } from "../security";
import ApiService from "../services/ApiService";

export default function Logout(){
    const api = useApi();
    const {setAuth} = useAuth();
    const nav = useNavigate();

    useEffect(() =>{
        api.signout();
        setAuth(null);
        nav('/');
    },[]);

    return (<div>Logging out...</div>);
}