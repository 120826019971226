import React, { Children, useState } from "react";
import Card from "./Card";

function TabItem(props) {
    if (!props.visible) {
        return (<></>);
    }
    return (
        <li className="nav-item" role="presentation">
            <button className={('nav-link tw-text-primary ' + (props.active ? 'active' : ''))}
                data-bs-toggle="tab"
                data-bs-target={('#' + props.id)}
                data-index={props.index}
                onClick={e => props?.onChanged(e)}>{props.title}</button>
        </li>
    );
}
function TabContent(props) {
    const children = Children.map(Children.toArray(props.children), (child, idx) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { active: props.active });
        }
        return child;
    });
    if (!props.visible) {
        return (<></>);
    }
    return (
        <div className={('tab-pane ' + (props.active ? 'active' : ''))} id={props.id} role="tabpanel">
            {children}
        </div>
    );
}

export function Tab(props) {
    // Empty prop container to house a tab element which
    // will be seprated into a tab item and tab content.
    return (<></>);
}

export function Tabs(props) {
    const tabsId = props.id || (Math.random() + 1).toString(36).substring(7);
    const contentId = tabsId + '-content';
    const children = Children.toArray(props.children);

    const [activeIndex, setActiveIndex] = useState(0);

    function onTabChanged(e) {
        setActiveIndex(parseInt(e.target.dataset.index));
    }

    return (
        <Card className="tw-w-full tw-mx-0">
            <ul id={tabsId} className="nav nav-tabs" role="tablist">
                {Children.map(children, (child, index) => {
                    const visible = (child.props.hasOwnProperty('visible') ? child.props.visible : true);
                    return (<TabItem key={index} index={index} active={activeIndex === index} {...child.props} visible={visible} onChanged={onTabChanged} />);
                })}
            </ul>

            <div id={contentId} className="tab-content tw-flex tw-flex-col tw-w-full tw-m-3">
                {Children.map(children, (child, index) => {
                    const visible = (child.props.hasOwnProperty('visible') ? child.props.visible : true);
                    return (<TabContent key={index} index={index} active={activeIndex === index} {...child.props} visible={visible} />);
                })}
            </div>
        </Card>
    );
}