import { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './Avatar.css';
import blank from './blank_profile.webp';
import { useFetcher } from 'react-router-dom';

export default function Avatar(props){
    const fileRef = useRef(null);
    const title = props.image ? 'Change Image' : 'Upload Image';

    const [image, setImage] = useState(props.image);// || blank);

    const { getRootProps, getInputProps, isDragActive, open} = useDropzone({
        noClick:true,
        noKeyboard:true,
        onDrop: onDrop
    });

    function onImageUpdated(file){
        if (!file){
            return;
        }
        setImage(URL.createObjectURL(file));
        props?.onImageChanged(file);
    }

    function onDrop(acceptedFiles){
        onImageUpdated(acceptedFiles[0]);
    }

    function updateImage(e){
        let file = e.target.files[0];
        onImageUpdated(file);
    }

    function getFile(){
        fileRef.current.click();
    }

    useEffect(() =>{
        setImage(props.image);
    },[props.image]);

    //console.log('AVATAR:', {image, props});

    return (
        <div className="avatar" {...getRootProps()}>
            <img src={image || blank} referrerPolicy="no-referrer"/>
            {isDragActive ? (<div className='text-center'>Drop here to upload file</div>) : (<div className='text-center'>Drag and drop file here</div>)}
            <div className='text-center'>or</div>
            <button className="btn btn-primary" onClick={getFile}>{title}</button>
            <input type="file" name="photo" style={{display:'none'}} accept=".jpg,.jpeg,.png,.webp,.heif" ref={fileRef} onChange={e => updateImage(e)} {...getInputProps()}/>
            <p className="help text-center">File format: JPG, PNG, WEBP, HEIF</p>
        </div>
    );
}