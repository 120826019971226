import { useEffect, useState, useCallback, useReducer } from 'react';

export const useTitle = (title) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
    return null;
};

export function useCustomState(initialState, setter){
    const [state, setState] = useState(initialState);
    const setCustomValue = useCallback((newValue)=>{
        setState(newValue);
        setter(newValue);
    }, []);

    return [state, setCustomValue];
}

export function useObjectState(obj, key, initialState = ''){
    const [state, setState] = useState((obj || {})[key] || initialState);
    const setCustomValue = useCallback((value)=>{
        setState(value);
        (obj || {})[key] = value;
    });
    return [state, setCustomValue];
}