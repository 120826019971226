import { useEffect, useState } from "react";
import { Roles } from "../types";
import { useApi, useAuth } from "../security";
import Alert from "./Alert";
import BusyIndicator from "./BusyIndicator";

function PaymentPreferenceCheck(props){
    const user = props.user;
    const territories = props.territories || [];

    const [name, setName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [territory, setTerritory] = useState('');
    const [postalCode, setPostalCode] = useState('');

    return (
        <>
        <div className="row">
            <div className="col">
                <label>Name</label>
                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Address 1</label>
                <input type="text" className="form-control" value={address1} onChange={e => setAddress1(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Address 2</label>
                <input type="text" className="form-control" value={address2} onChange={e => setAddress2(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>City</label>
                <input type="text" className="form-control" value={city} onChange={e => setCity(e.target.value)}/>
            </div>
            <div className="col-2">
                <label>State</label>
                <select className="form-select" name='territory' value={territory} onChange={(e) => setTerritory(e.currentTarget.value)}>
                    <option value=''></option>
                    {(territories || []).map((s,i) => <option key={i} value={s.code}>{s.name}</option>)}
                </select>
            </div>
            <div className="col-3">
                <label>Postal Code</label>
                <input type="text" className="form-control" value={postalCode} onChange={e => setPostalCode(e.target.value)}/>
            </div>
        </div>
        </>
    );
}

function PaymentPreferenceAch(props){
    const user = props.user;

    const [accountName, setAccountName] = useState('');
    const [bankName, setBankName] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [accountNumberConfirm, setAccountNumberConfirm] = useState('');

    return (
        <>
        <div className="row">
            <div className="col">
                <label>Account Name</label>
                <input type="text" className="form-control" value={accountName} onChange={e => setAccountName(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Bank Name</label>
                <input type="text" className="form-control" value={bankName} onChange={e => setBankName(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Routing Number</label>
                <input type="text" className="form-control" value={routingNumber} onChange={e => setRoutingNumber(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Account Number</label>
                <input type="text" className="form-control" value={accountNumber} onChange={e => setAccountNumber(e.target.value)}/>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <label>Confirm Account Number</label>
                <input type="text" className="form-control" value={accountNumberConfirm} onChange={e => setAccountNumberConfirm(e.target.value)}/>
            </div>
        </div>
        </>
    );
}

export default function PaymentDetails(props){
    const api = useApi();
    const {auth} = useAuth();
    const user = props.user;

    const [paymentPrefs, setPaymentPrefs] = useState([]);
    const [territories, setTerritories] = useState([]);

    const [paymentConfirmed, setPaymentConfirmed] = useState(false);
    const [selectedPaymentPref, setSelectedPaymentPref] = useState('');

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    function onPaymentConfirmedChanged(e){
        const newValue = !paymentConfirmed;
        setPaymentConfirmed(newValue);
    }

    useEffect(() =>{
        setIsBusy(true);
        api.common.paymentTypes()
            .then(r =>{
                setPaymentPrefs(r.data);
                api.common.territories()
                    .then(r =>{
                        setIsBusy(false);
                        setTerritories(r.data);
                    }).catch(e =>{
                        setIsBusy(false);
                        setError(e.toString());
                    });
            }).catch(e =>{
                setIsBusy(false);
                setError(e.toString());
            });
    },[]);

    if (isBusy){
        return (<BusyIndicator/>);
    }

    return (<>
        <Alert error={error} success={success}/>
        {Roles.isInternalUser(auth.roles)
            && (
                <div className="row mb-3">
                    <div className="col">
                        <div className='d-inline ms-2 me-2'>
                            <input type="checkbox" className="btn-check" id="paymentConfirmed" autoComplete="off" checked={paymentConfirmed} onChange={onPaymentConfirmedChanged}/>
                            <label className="btn btn-outline-primary" htmlFor="paymentConfirmed">Payment details verified</label>
                        </div>
                    </div>
                </div>
            )}
        <div className="row mb-3">
            <div className="col">
                <label>Payment Preference</label>
                <div>
                    {paymentPrefs.map((pref,index)=>{
                        return (
                            <div className="d-inline ms-2 me-2" key={index}>
                                <input type="radio" 
                                    class="btn-check" 
                                    name="paymentPreference" 
                                    id={pref.id} 
                                    autocomplete="off"
                                    checked={(selectedPaymentPref?.id === pref.id)}
                                    onChange={e => setSelectedPaymentPref(pref)}/>
                                <label class="btn btn-outline-primary" htmlFor={pref.id}>{pref.name}</label>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        { selectedPaymentPref &&
            ([selectedPaymentPref]).map((pref, index)=>{
                if (pref.id === 'check'){
                    return (<PaymentPreferenceCheck key={index} territories={territories}/>);
                } else if (pref.id === 'ach'){
                    return (<PaymentPreferenceAch key={index}/>);
                } else {
                    return (<></>);
                }
            })
        }
    </>);
}