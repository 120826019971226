import { useCallback, useEffect, useState } from "react";
import { useApi } from "../security";
import SearchOrganizations from "./SearchOrganizations";
import BusyIndicator from "./BusyIndicator";
import Alert from "./Alert";
import { useObjectState } from "../hooks";
import ResultBox from "./ResultBox";
import ResultBoxRow from "./ResultBoxRow";

const OrgMgmtState = {
    search: 0,
    add: 1,
    edit: 2
}

export function OfficeLocations(props) {
    const organization = props.organization;
    const territories = props.territories;

    const setError = props.setError || function () { };

    const [locations, setLocations] = useObjectState(organization, 'locations', organization?.locations || []);

    const [currentLocation, setCurrentLocation] = useState();

    const [curName, setCurName] = useState('');
    const [curCity, setCurCity] = useState('');
    const [curTerritory, setCurTerritory] = useState('');
    const [curPostalCode, setCurPostalCode] = useState('');

    function add() {
        setError('');
        const location = { parentId: organization.id };
        edit(location);
        setLocations([...locations, location]);
    }

    function edit(location) {
        setError('');
        setCurrentLocation(location);
        setCurName(location.name || '');
        setCurCity(location.city || '');
        setCurTerritory(location.territory || '');
        setCurPostalCode(location.postalCode1 || '');
    }

    function save() {
        setError('');
        if (!curName) {
            setError('Other office location name is required.');
            return;
        }
        if (!curPostalCode) {
            if (!curCity && !curTerritory) {
                setError('Other office location city and state or postal code are required.');
                return;
            }
        }

        let loc = organization?.locations?.find(o => o.id === currentLocation.id);
        if (loc) {
            loc.name = curName;
            loc.city = curCity;
            loc.territory = curTerritory;
            loc.postalCode1 = curPostalCode;
        }
        setCurrentLocation(null);
        setCurName('');
        setCurCity('');
        setCurTerritory('');
        setCurPostalCode('');
        console.log('SAVE:', organization);
    }

    function remove() {
        setError('');
        console.log('REMOVE:', currentLocation);
    }

    function cancel() {
        setError('');
        if (currentLocation && !currentLocation.id) {
            let locs = [...locations];
            const index = locs.indexOf(currentLocation);
            if (index >= 0) {
                locs.splice(index, 1);
            }
            setLocations(locs);
        }
        setCurrentLocation(null);
    }

    useEffect(() => {
        if (props.location) {
            edit(props.location);
        }
    }, [props.location]);

    return (
        <div className="tw-flex tw-flex-wrap tw-justify-center">
            <h4 className="mt-4 tw-w-full">Other Offices/Locations</h4>
            {(locations || []).map((location, idx) => {
                if (currentLocation !== null && location?.id === currentLocation?.id) {
                    return (
                        <ResultBox>
                            <input type="text" placeholder="Name" className="form-control tw-my-2" value={curName} onChange={e => setCurName(e.target.value)} />
                            <input type="text" placeholder="City" className="form-control tw-my-2" value={curCity} onChange={e => setCurCity(e.target.value)} />

                            <select className="form-select tw-my-2" name='territory' value={curTerritory} onChange={e => setCurTerritory(e.target.value)}>
                                <option value="" disabled selected hidden>Territory</option>
                                {(territories || []).map((s, i) => <option key={i} value={s.code}>{s.name}</option>)}
                            </select>

                            <input type="text" placeholder="Postal Code" className="form-control tw-my-2" value={curPostalCode} onChange={e => setCurPostalCode(e.target.value)} />
                            <div className="tw-flex max-sm:tw-flex-col tw-items-center tw-justify-center tw-my-2">
                                <button className="btn btn-outline-danger tw-my-2" onClick={cancel}>Cancel</button>
                                <button className="btn btn-success" onClick={save}>{(!location?.id ? 'Save' : 'Update')}</button>
                            </div>
                        </ResultBox>
                    );
                }
                return (
                    <ResultBox>
                        <ResultBoxRow name="Name:" value={location.name} />
                        <ResultBoxRow name="Address:" value={location.city + ", " + location.territory + " " + location.postalCode1} />
                        <button className="btn btn-primary" onClick={e => edit(location)}>Edit</button>
                    </ResultBox>
                );
            })}
            <div className="tw-w-full">
                {!currentLocation && (<td className="d-flex justify-content-end"><button className="btn btn-primary" onClick={add}>+</button></td>)}
                {currentLocation && (<td></td>)}
            </div>
        </div>
    );
}

export function PrimaryOffice(props) {
    const organization = props.organization;
    const territories = props.territories;

    const setError = props.setError || function () { };

    const [name, setName] = useObjectState(organization, 'name');
    const [city, setCity] = useObjectState(organization, 'city');
    const [territory, setTerritory] = useObjectState(organization, 'territory');
    const [postalCode, setPostalCode] = useObjectState(organization, 'postalCode1');

    const [description, setDescription] = useObjectState(organization, 'description');
    const [website, setWebsite] = useObjectState(organization, 'webSite');

    return (
        <>
            <h4 className="mt-4">Medical Group Details</h4>
            <div className="row">
                <div className="col">
                    <label>Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={name} onChange={e => setName(e.target.value)} />
                </div>
            </div>
            <h4 className="mt-4">Primary Office</h4>
            <div className="row">
                <div className="col">
                    <label>City</label>
                    <input type="text" className="form-control" value={city} onChange={e => setCity(e.target.value)} />
                </div>
                <div className="col-3">
                    <label>State</label>
                    <select className="form-select" name='territory' value={territory} onChange={(e) => setTerritory(e.currentTarget.value)}>
                        <option value=''></option>
                        {(territories || []).map((s, i) => <option key={i} value={s.code}>{s.name}</option>)}
                    </select>
                </div>
                <div className="col-3">
                    <label>Postal code</label>
                    <input type="text" className="form-control" value={postalCode} onChange={e => setPostalCode(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Description</label>
                    <input type="text" className="form-control" value={description} onChange={e => setDescription(e.target.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Website</label>
                    <input type="text" className="form-control" value={website} onChange={e => setWebsite(e.target.value)} />
                </div>
            </div>
        </>
    );
}

export function OrganizationEditor(props) {
    const organization = props.organization;
    const territories = props.territories;
    const location = props.location;
    const isAdding = props.isAdding;
    const setError = props.setError || function () { };

    return (
        <>
            <h3>{(isAdding ? 'Add New Medical Group/Practice' : 'Edit Medical Group/Practice')}</h3>

            <PrimaryOffice organization={organization} territories={territories} setError={setError} />
            <OfficeLocations organization={organization} territories={territories} location={location} setError={setError} />

            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                <button className="btn btn-outline-danger" onClick={props?.onCancel}>{(isAdding ? 'Cancel' : 'Exit')}</button>
                <button className="btn btn-primary" onClick={props?.onSave}>{(isAdding ? 'Add Medical Group' : 'Update Medical Group')}</button>
            </div>
        </>
    );
}

export default function OrganizationManagement(props) {
    const api = useApi();

    const [state, setState] = useState(props.state || OrgMgmtState.search);

    const [territories, setTerritories] = useState([]);
    const [organization, setOrganization] = useState();
    const [location, setLocation] = useState();
    const [searchCriteria, setSearchCriteria] = useState({});
    const [searchResults, setSearchResults] = useState([]);

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    function setApiError(msg) {
        setIsBusy(false);
        setError(msg);
    }

    function onSearch(criteria) {
        setError('');
        if (!criteria.territory) {
            setError('State/Territory is required.');
            return;
        }

        setSearchCriteria(criteria);
        setIsBusy(true);
        api.organizations.search(criteria)
            .then(r => {
                setIsBusy(false);
                setSearchResults(r.data);
            }).catch(e => setApiError('Could not execute search.'));
    }

    function onSearchComplete(response) {
        console.log('ORGS: Search complete:', response);
        setSearchCriteria(response);
        setSearchResults(response.results);
    }

    function cancel() {
        setError('');
        setSuccess('');
        setState(OrgMgmtState.search);
    }
    function save() {
        console.log('ORGS: Save:', organization);
        setError('');
        setSuccess('');
        if (!organization.name) {
            setError('Primary office name is required.');
            return;
        }
        if (!organization.postalCode1) {
            if (!organization.city && !organization.territory) {
                setError('Primary office location city and state or postal code are required.');
                return;
            }
        }

        setIsBusy(true);
        api.organizations.save(organization)
            .then(r => {
                setIsBusy(false);
                load(r.data);
                setSuccess('Medical group saved successfully.');
            }).catch(e => setApiError('Could not save medical group.'));
    }

    function onAdd() {
        console.log('ORGS: Add new group');
        setState(OrgMgmtState.add);
        setOrganization({});
        setLocation(null);
    }

    function onEdit(org) {
        console.log('ORGS: Edit group:', org);
        setError('');
        setIsBusy(true);
        setLocation(null);
        api.organizations.load(org.id)
            .then(r => {
                console.log('ORGS: Edit:', r.data);
                setIsBusy(false);
                setState(OrgMgmtState.edit);
                setOrganization(r.data);
                if (r.data.id !== org.id) {
                    setLocation(org);
                }
            }).catch(e => setApiError('Could not find the selected medical group.'));
    }

    function load(org) {
        setOrganization(org);
        setLocation(null);
        setState(OrgMgmtState.edit);
    }

    useEffect(() => {
        api.common.territories()
            .then(r => setTerritories(r.data));
    }, []);

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (
        <>
            <Alert error={error} success={success} />
            {state !== OrgMgmtState.search
                && (
                    <OrganizationEditor
                        isAdding={(state === OrgMgmtState.add)}
                        organization={organization}
                        territories={territories}
                        location={location}
                        setError={setError}
                        onCancel={cancel}
                        onSave={save} />
                )}

            {state === OrgMgmtState.search
                && (
                    <SearchOrganizations
                        territories={territories}
                        criteria={searchCriteria}
                        results={searchResults}
                        onAdd={onAdd}
                        onSelected={onEdit}
                        onSearch={onSearch}
                        onSearchComplete={onSearchComplete} />
                )}
        </>
    );
}