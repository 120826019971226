import { useEffect, useState } from "react";
import { useApi, useAuth } from "../security";
import jwt_decode from "jwt-decode";
import Avatar from "../components/Avatar";
import BusyIndicator from "../components/BusyIndicator";

export default function Photo(){
    const {auth} = useAuth();
    const api = useApi();

    const [photo, setPhoto] = useState();
    const [error, setError] = useState();
    const [isBusy, setIsBusy] = useState();

    function updatePhoto(file){
        setIsBusy(true);
        api.identity.photo(file)
            .then(r =>{
                setIsBusy(false);
            }).catch(e =>{
                setError(e?.response?.data ?? e?.message);
                setIsBusy(false);
            });
    }

    useEffect(()=>{
        console.log(auth);
        var decoded = jwt_decode(auth.accessToken);
        console.log(decoded);
        setPhoto(decoded["photo"]);
    },[]);

    if (isBusy){
        return (<BusyIndicator/>);
    }

    return (
        <div className="row">
            <div className="col-4">
            {error && <div className="alert alert-danger">{error}</div>}
            <Avatar image={photo} onImageChanged={updatePhoto}/>
            </div>
        </div>
        );
}