import { useEffect, useMemo, useState } from "react";
import { useApi, useAuth } from "../security";
import { UserAccessScopes, Roles } from "../types";
import BusyIndicator from "../components/BusyIndicator";
import { useNavigate, useParams } from "react-router-dom";
import GeneralInformation from "../components/GeneralInformation";
import Scheduling from "../components/Scheduling";
import AccountManagement from "../components/AccountManagement";
import { Tabs, Tab } from "../components/Navigation";
import PaymentDetails from "../components/PaymentDetails";
import Earnings from "../components/Earnings";
import Contract from "../components/Contract";
import OrganizationSelector from "../components/OrganizationSelector";
import Card from "../components/Card";

export default function HomeUsers(props) {
    const api = useApi();
    const nav = useNavigate();
    const { auth } = useAuth();
    const [user, setUser] = useState();
    const [scopes, setScopes] = useState([]);

    const parameters = useParams();
    const id = props.id || parameters.id || auth.sub || auth.nameid;

    useEffect(() => {
        console.log('USER_DETAILS: Loading user details: ', { id, parameters, props });
        api.common.load().then(() => {
            api.users.details(id)
                .then(r => {
                    setUser({ ...r.data.user, roles: r.data.roles });
                    setScopes(r.data.scopes);
                    console.log('USER_DETAILS: Details:', r.data);
                }).catch(e => nav('/unauthorized'));
        });
    }, []);

    if (!user) {
        return (<BusyIndicator />);
    }

    const tabProps = { api, auth, user, scopes };
    return (
        <div className="tw-m-10 tw-mt-2">
            <Card className="tw-my-2 tw-mx-0 tw-w-fit">
                <div className="tw-flex tw-wrap tw-items-center tw-justify-center">
                    <h5>{user.fullName} ({user.email})</h5>
                    {!user.isActive && (<span className="fw-bold alert alert-danger tw-my-0 tw-ml-5 tw-text-center">Not Activated</span>)}
                </div>
            </Card>
            <Tabs id='user'>
                <Tab title='General Information'>
                    <GeneralInformation {...tabProps} />
                </Tab>
                <Tab title='Medical Group/Practice' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.user.organization) && Roles.isDoctor(user.roles)}>
                    <OrganizationSelector {...tabProps} />
                </Tab>
                <Tab title='Scheduling' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.scheduling.root) && Roles.isDoctor(user.roles)}>
                    <Scheduling {...tabProps} />
                </Tab>
                <Tab title='Contract' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.contract.root) && Roles.isDoctor(user.roles)}>
                    <Contract {...tabProps} />
                </Tab>
                <Tab title='Payment Details' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.payments.root) && Roles.isDoctor(user.roles)}>
                    <PaymentDetails {...tabProps} />
                </Tab>
                <Tab title='Earnings' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.earnings.root) && Roles.isDoctor(user.roles)}>
                    <Earnings {...tabProps} />
                </Tab>
                <Tab title='Account Management' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.user.manage) && Roles.isDoctor(user.roles)}>
                    <AccountManagement {...tabProps} />
                </Tab>
                <Tab title='Audit History' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.audit.root)}>

                </Tab>
                <Tab title='Admin' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.admin.root)}>

                </Tab>
            </Tabs>
        </div>
    );
}