import './App.css';
import Layout from './components/Layout';
import {Route, Routes} from 'react-router-dom';
import AuthorizeRoutes from './routes/AuthorizeRoutes';
import Identity from './identity/Identity';
import Login from './identity/Login';
import Logout from './identity/Logout';
import Photo from './identity/Photo';
import ForgotPassword from './identity/ForgotPassword';
import Confirm from './identity/Confirm';
import {default as DoctorRegister} from './identity/doctor/Register';
import Register from './identity/Register';
import Home from './pages/Home';
import Search from './pages/Search';
import Reserve from './pages/Reserve';
import ReservationConfirmation from './pages/ReservationConfirmation';
import AccessDenied from './pages/AccessDenied';
import HomeUsers from './pages/HomeUsers';
import HomeInternalUsers from './pages/HomeInternalUsers';
import HomeSchedulers from './pages/HomeSchedulers';
function App() {
  return (
      <Layout>
        <Routes>
          <Route element={<AuthorizeRoutes/>}>
            <Route path='users'>
              <Route index element={<HomeUsers/>}/>
              <Route path='edit/:id' element={<HomeUsers/>}/>
              <Route path='dashboard' element={<HomeInternalUsers/>}/>
              <Route path='list' element={<HomeSchedulers/>}/>
            </Route>
          </Route>

          <Route path='register'>
            <Route path=':token' element={<Register/>}/>
            <Route path='doctor' element={<DoctorRegister/>}/>
          </Route>

          <Route path='identity'>
            <Route index element={<Identity/>} />
            <Route path='logout' element={<Logout/>}/>
            <Route path='login' element={<Login/>}/>
            <Route path='signup' element={<Register/>}/>
            <Route path='confirm' element={<Confirm/>}/>
            <Route path='forgotpassword' element={<ForgotPassword/>}/>
            <Route path='photo' element={<Photo/>}/>
          </Route>

          <Route path='reserve'>
            <Route index element={<Reserve/>}/>
            <Route path='confirm/:id' element={<ReservationConfirmation/>}/>
          </Route>
          <Route path='search' element={<Search/>}/>
          <Route path='unauthorized' element={<AccessDenied/>}/>
          <Route index element={<Home/>}/>
        </Routes>
      </Layout>
  );
}

export default App;
