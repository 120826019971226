import { createContext, useCallback, useContext, useState } from 'react';
import ApiService from './services/ApiService';

const AuthContext = createContext({
    auth: null,
    api:{},
    setAuth: (value) => {}
});

function useAuthState(api){
    const [state, setState] = useState(api.auth);
    
    const setter = useCallback((value)=>{
        setState(value);
        api.setToken(value);
    },[]);
    return [state, setter];
}

export const useAuth = ()=> useContext(AuthContext);
export const useApi = () => {const {api} = useAuth(); return api;}

export function AuthProvider(props){
    const api = ApiService.instance;
    const [auth, setAuth] = useAuthState(api);
    return (
        <AuthContext.Provider value={{auth, setAuth, api}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;