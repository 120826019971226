import { useEffect, useState } from "react";
import { useApi } from "../security";
import BusyIndicator from "./BusyIndicator";
import Alert from "./Alert";
import ResultBox from "./ResultBox";
import ResultBoxRow from "./ResultBoxRow";

function SearchResultRow(props) {
    const result = props.result;
    const dt = new Date(result.dateAdded);
    const link = '/users/edit/' + result.id;

    return (<ResultBox headerText={result?.contactName || (result?.firstName + ' ' + result?.lastName)}>
        <ResultBoxRow name="Email:" value={result?.email} />
        <ResultBoxRow name="Speciality:" value={result?.specialty?.name} />
        <a href={link} className="btn btn-primary tw-break-normal tw-mt-2">Edit</a>
    </ResultBox>)
}

function SearchResults(props) {
    const results = props.results || { items: [], totalRecords: 0, nextPage: null };

    if (results?.length === 0) {
        return (<></>);
    }

    return (<>
        <hr />
        <div className="tw-flex tw-flex-wrap tw-justify-center">
            {(results?.items || []).map((r, i) => <SearchResultRow key={i} result={r} />)}
        </div>
    </>);
}

export default function SearchAccounts(props) {
    const api = useApi();

    const [territories, setTerritories] = useState([]);
    const [specialites, setSpecialties] = useState([]);
    const [selectedTerritory, setSelectedTerritory] = useState('');
    const [selectedSpecialty, setSelectedSpecialty] = useState('');
    const [results, setResults] = useState();

    const [isBusy, setIsBusy] = useState(true);
    const [error, setError] = useState();

    function search() {
        setError(null);
        if (!selectedTerritory) {
            setError('State/Territory is required.');
            return;
        }
        if (!selectedSpecialty) {
            setError('Specialty is required.');
            return;
        }

        setIsBusy(true);
        api.users.search({ territory: selectedTerritory, specialityId: selectedSpecialty, nextPage: results?.nextPage })
            .then(r => {
                setIsBusy(false);
                setResults(r.data);
                console.log('ACCOUNTS_SEARCH:', r.data);
            }).catch(e => onApiError(e.toString()));
    }

    function onApiError(msg) {
        setIsBusy(false);
        setError(msg);
    }

    useEffect(() => {
        api.common.territories()
            .then(rt => {
                setTerritories(rt.data);
                api.common.specialties()
                    .then(rs => {
                        setSpecialties(rs.data);
                        setIsBusy(false);
                    }).catch(e => onApiError('Could not fetch specialites.'))
            }).catch(e => onApiError('Could not fetch territories.'));
    }, []);

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (
        <>
            <Alert error={error} />
            <div className="row">
                <div className="col">
                    <label>State/Territory</label>
                    <select className="form-select" value={selectedTerritory} onChange={e => setSelectedTerritory(e.target.value)}>
                        <option></option>
                        {(territories || []).map((t, i) => <option key={i} value={t.code}>{t.name}</option>)}
                    </select>
                </div>
                <div className="col">
                    <label>Specialty</label>
                    <select className="form-select" value={selectedSpecialty} onChange={e => setSelectedSpecialty(e.target.value)}>
                        <option></option>
                        {(specialites || []).map((s, i) => <option key={i} value={s.id}>{s.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="row tw-my-3">
                <div className="col-auto ms-auto">
                    <button className="btn btn-primary" onClick={search}>Find Accounts</button>
                </div>
            </div>
            <SearchResults results={results} />
        </>
    );
}