
export function groupBy(arry, key){
    return arry.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
}

export function sortByStartTime(items){
  return items.sort((a,b)=>{
      const ah = parseInt(a?.startTime?.split(':')[0] || '-1');
      const bh = parseInt(b?.startTime?.split(':')[0] || '-1');
      const am = parseInt(a?.startTime?.split(':')[1] || '-1');
      const bm = parseInt(b?.startTime?.split(':')[1] || '-1');

      if (ah < bh){
          return -1;
      }
      if (ah > bh){
          return 1;
      }
      if (am < bm){
          return -1;
      }
      if (am > bm){
          return 1;
      }
      return 0;
  });
}

export function getHourRange(items){
  const hours = items.map((o,i) => parseInt(o?.startTime?.split(':')[0] ?? '-1'));
  let min = Math.min.apply(Math, hours);
  let max = Math.max.apply(Math, hours);
  if (min > 8){
    min = 8;
  }
  if (max < 18){
    max = 18;
  }
  const results = [];
  for (let i = 0; i <= (max - min); i++){
    results[i] = min + i;
  }
  return results;
}

export function getStartOfWeek(date){
  const newDate = new Date(date);
  const diff = newDate.getDate() - date.getDay();
  return new Date(newDate.setDate(diff));
}

export function addDays(date, number){
  const newDate = new Date(date);
  const diff = newDate.getDate() + number;
  return new Date(newDate.setDate(diff));
}

export function getDatePart(value){
  const dt = new Date(value);
  return dt.getFullYear() + '-' + String(dt.getMonth()+1).padStart(2, '0') + '-' + String(dt.getDate()).padStart(2, '0');
}
export function getTimePart(value){
  const dt = new Date(value);
  return String(dt.getHours()).padStart(2,'0') + ':' + String(dt.getMinutes()).padStart(2, '0') + ':' + String(dt.getSeconds()).padStart(2, '0');
}
export function toStartOfDay(value){
  return new Date(new Date(value).setUTCHours(0, 0, 0, 0));
}
export function toEndOfDay(value){
  return new Date(new Date(value).setUTCHours(23, 59, 59, 999));
}

export function getRange(start, stop, step = 1){
  return Array.from({length: (stop - start) / step + 1}, (_,i) => start + (i * step));
}