import Alert from "../components/Alert";
import { Roles } from "../types";
import ResultBox from "./ResultBox";
import ResultBoxRow from "./ResultBoxRow";

function TaskRow(props) {
    const user = props.user || {};
    const link = '/users/edit/' + user.id;
    const dt = new Date(user.dateAdded);

    return (<ResultBox headerText={dt.toDateString()}>
        <ResultBoxRow name="Email:" value={user.email} />
        <ResultBoxRow name="Role:" value={Roles.getName(Roles.getRole(user.type))} />
        <ResultBoxRow className={`${(user.contactName == null && (user.firstName + ' ' + user.lastName == ' ')) && 'tw-hidden'}`} name="Contact Name:" value={user.contactName || (user.firstName + ' ' + user.lastName)} />
        <ResultBoxRow className={`${(user.contactPhone == null) && 'tw-hidden'}`} name="Contact Phone:" value={user.contactPhone} />
        <ResultBoxRow className={`${(user.contactEmail == null) && 'tw-hidden'}`} name="Contact Email:" value={user.contactEmail} />
        <a href={link} className="btn btn-primary tw-break-normal tw-mt-2">Edit</a>
    </ResultBox>)
}

export default function TaskTable(props) {
    const users = props.users || [];
    const error = props.error;
    return (
        <>
            <Alert error={error} />
            <div className="tw-flex tw-flex-wrap tw-justify-center">
                {(users || []).map((u, i) => <TaskRow key={i} user={u} />)}
            </div>
        </>
    );
}