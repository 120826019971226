import info from '../info.svg';
import { useState } from 'react';

export default function PasswordRequirements(props){
    const rules = props.rules;

    const [visible, setVisible] = useState(false);
    const [style, setStyle] = useState({display:'none'});

    function toggle(show){
        setVisible(show);
        setStyle({display:(show ? 'block' : 'none')});
    }
    return (
        <div>
            <a href='#' onClick={e => {e.preventDefault(); toggle(!visible);}} onMouseOver={() => toggle(true)} onMouseLeave={() => toggle(false)}><img src={info}/></a>
            <ul style={style}>
                {rules && rules.map((r,i) => (<li key={i}>{r}</li>))}
            </ul>
        </div>
    );
}