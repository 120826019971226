import React, { useEffect, useMemo, useState } from "react";
import { getHourRange, groupBy, sortByStartTime } from "../helpers";
import {useCustomState} from '../hooks';
import { AppointmentConfigurationItemUpdateAction, AppointmentConfigurationUpdateAction, Timespan } from "../types";
import './AppointmentConfiguration.css';
import add from './add_outline.png';
import remove from './delete_outline.png';
import check from './check_outline.png';
import Card from "./Card";
 
function TimeDisplay(props){
    return (
        <div onClick={props?.onClick}>
            {props?.startTime?.getShortString()} - {props?.endTime?.getShortString()}
        </div>
    );
}

function TimeEdit(props){
    const startTime = props.startTime;

    const [hour, setHour] = useState(startTime?.getHourString() || '8');
    const [minute, setMinute] = useState(startTime?.getMinutesString() || '00');
    const [amPm, setAmPm] = useState(startTime?.isPM() ? 'PM' : 'AM');

    const hours = [1,2,3,4,5,6,7,8,9,10,11,12];
    const minutes = ['00','15','30','45'];
    const amPms = ['AM','PM'];

    function updateHour(value){
        setHour(value);
        update(value, minute, amPm);
    }
    function updateMinute(value){
        setMinute(value);
        update(hour, value, amPm)
    }
    function updateAmPm(value){
        setAmPm(value);
        update(hour, minute, value);
    }

    function update(h,m,a){
        const hh = parseInt(h);
        if (a == 'PM')
            hh += 12;
        props?.onUpdate(String(hh).padStart(2, '0') + ':' + m + ':00');
    }

    return (
        <div className="appt-duration-edit">
            <div><select value={hour} onChange={e => updateHour(e.currentTarget.value)}>{hours.map((h,i) => <option key={i} value={h}>{h}</option>)}</select></div>
            <div><select value={minute} onChange={e => updateMinute(e.currentTarget.value)}>{minutes.map((m,i) => <option key={i} value={m}>{m}</option>)}</select></div>
            <div><select value={amPm} onChange={e => updateAmPm(e.currentTarget.value)}>{amPms.map((a,i) => <option key={i} value={a}>{a}</option>)}</select></div>
        </div>
    );
}

function TimeOption(props){
    const item = props.item || {startTime:'00:00:00'};
    const duration = new Timespan(props.duration || '00:15:00');
    const startTime = new Timespan(item.startTime || '00:00:00');
    const endTime = new Timespan(startTime.getString()).add(duration);
    const hours = props.hours || [];

    const [isEditing, setIsEditing] = useState(false);

    function update(){
        item.startTime = startTime.getString();
        setIsEditing(false);
        props?.onUpdate(item);
    }

    function enterEditMode(){
        setIsEditing(true);
    }

    function onEditUpdate(value){
        startTime.update(value);
    }

    function getUpdateButton(){
        return (
            <React.Fragment>
                <div className="button">
                    <button className="btn" onClick={update}><img src={check} className="btnCalendar" alt="update" /></button>
                </div>
                <div className="button"></div>
            </React.Fragment>
        );
    }
    function getActionButtons(){
        return (
            <React.Fragment>
                <div className="button">
                    <button className="btn" onClick={(e) => props?.onRemove(item?.id)}><img src={remove} className="btnCalendar" alt="remove"/></button>
                </div>
                <div className="button">
                    {props.first === true && <button className="btn" onClick={(e) => props?.onAdd()}><img src={add} className="btnCalendar" alt="add"/></button>}
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="mb-2 appt-time tw-flex">
            <div className="appt-duration tw-min-w-max tw-w-2/3">
                {isEditing ? <TimeEdit startTime={startTime} onUpdate={onEditUpdate}/> : <TimeDisplay startTime={startTime} endTime={endTime} onClick={enterEditMode}/>}
            </div>
            {isEditing ? getUpdateButton() : getActionButtons()}
        </div>
    );
}

function DayOption(props){
    const dayOfWeek = parseInt(props.dayOfWeek || '0');
    let items = sortByStartTime([...props.items] || []);
    const hours = getHourRange(items);
    const duration = props.duration || '00:00:00';

    let day = 'SUN';
    switch (dayOfWeek){
        case 1:
            day = 'MON';
            break;
        case 2:
            day = 'TUE';
            break;
        case 3:
            day = 'WED';
            break;
        case 4:
            day = 'THU';
            break;
        case 5:
            day = 'FRI';
            break;
        case 6:
            day = 'SAT';
            break;
    }

    function onAdd(){
        const sorted = sortByStartTime(items);
        const last = sorted[sorted.length - 1] || {startTime:'08:00:00'};
        const time = new Timespan(last.startTime);
        const dur = new Timespan(duration);
        time.addMinutes(dur.minutes);
        const item = {startTime:time.getString(), dayOfWeek:dayOfWeek};
        items =[...items, item];
        props?.onAdd(item);
    }

    return (
        <div className="mt-4 appt-day">
            <div>
                {day}
            </div>
            <div className="tw-relative ">
                {items.map((t,i) => <TimeOption key={i} item={t} duration={props.duration} hours={hours} first={i===0} onRemove={props?.onRemove} onAdd={onAdd} onUpdate={item => props?.onUpdate(item)}/>)}
            </div>
        </div>
    );
}

function AppointmentConfigurationCheckbox(props){
    return (
        <div className="row mt-4">
            <div className="col-1">
                <input type='checkbox' checked={props.checked} onChange={e => props.onChange(e.currentTarget.value)}/>
            </div>
            <div className="col">
                <div>{props.label}</div>
            </div>
        </div>
    );
}

export default function AppointmentConfiguration(props){
    const config = props.configuration || {duration:'', repeatType:'', items:[]};
    const durations = props.durations || [];
    const repeatTypes = props.repeatTypes || [];
    const organization = props?.organization;
    const organizations = props?.organizations || [];

    const [duration, setDuration] = useCustomState(config.duration, v => config.duration = v);
    const [repeatType, setRepeatType] = useCustomState(config.repeatType, v => config.repeatType = v);

    const days = groupBy([...config.items], 'dayOfWeek');

    function updateDuration(value){ 
        setDuration(value);
        props.onConfigChanged(AppointmentConfigurationUpdateAction.duration, {duration:value});   
    }
    function updateRepeatType(value){
        setRepeatType(value);
        props.onConfigChanged(AppointmentConfigurationUpdateAction.repeatType, {repeatType:parseInt(value)});
    }
    function updateOrganization(value){
        props.onOrganizationChanged(value);
    }

    function onRemove(id){
        props.onConfigChanged(AppointmentConfigurationUpdateAction.item,{
            items:[
                {
                    action:AppointmentConfigurationItemUpdateAction.remove,
                    item:{id}
                }
            ]
        });
    }
    function onAdd(item){
        props.onConfigChanged(AppointmentConfigurationUpdateAction.item,{
            items:[
                {
                    action:AppointmentConfigurationItemUpdateAction.add,
                    item:item
                }
            ]
        });
    }
    function onUpdate(item){
        props.onConfigChanged(AppointmentConfigurationUpdateAction.item,{
            items:[
                {
                    action:AppointmentConfigurationItemUpdateAction.update,
                    item:item
                }
            ]
        });
    }

    useEffect(() =>{
        setDuration(config.duration);
        setRepeatType(config.repeatType);
    },[props.configuration]);

    return (
        <div className="appt-config">

            <div className="row mt-4">
                <div className="col">
                    <h5>Set up appointments</h5>
                </div>
                <div className="col d-flex justify-content-end">
                    <button className="btn btn-outline-primary" onClick={e => props.onRevertToDefault()}>Revert to Default</button>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <label>Organization</label>
                    <select className="form-control" value={organization?.id} onChange={e => updateOrganization(e.currentTarget.value)}>
                        {organizations.map((d,i) => <option key={i} value={d.id}>{d.name}</option>)}
                    </select>
                </div>
            </div>

            {/* <div className="row mt-4">
                <div className="col">
                    <label>Appointment duration</label>
                    <select className="form-control" value={duration} onChange={e => updateDuration(e.currentTarget.value)}>
                        {durations.map((d,i) => <option key={i} value={d.key}>{d.value}</option>)}
                    </select>
                </div>
            </div>

            <div className="row mt-4 mb-4">
                <div className="col">
                    <label>Appointment cadence</label>
                    <select className="form-control" value={repeatType} onChange={e => updateRepeatType(e.currentTarget.value)}>
                        {repeatTypes.map((r,i) => <option key={i} value={r.key}>{r.value}</option>)}
                    </select>
                </div>
            </div> */}
            
            {Object.keys(days).map((k,i) => <DayOption key={i} dayOfWeek={k} items={days[k]} duration={duration} onRemove={onRemove} onAdd={onAdd} onUpdate={onUpdate}/>)}
        </div>
    );
}

// export default function AppointmentConfigurationCollection(props){
//     const collection = props.collection || {configurations:[], durations:[], repeatTypes:[]};
    
//     const [selectedConfig, setSelectedConfig] = useState();

//     function selectionChanged(selected, config){
//         if (selected === 'on'){
//             setSelectedConfig(config);
//             props?.onChange(config);
//         }else{
//             setSelectedConfig(null);
//             props?.onChange(null);
//         }
//     }

//     return (
//         <div>
//             <h5>Set up appointments</h5>

//             {collection.configurations?.map((c,i) => <AppointmentConfigurationCheckbox 
//                 key={i}
//                 label={c.label} 
//                 checked={(selectedConfig === c)} 
//                 onChange={e => selectionChanged(e,c)}/>)}
//             <div className="row mt-4">
//                 <div className="col-1">
//                 </div>
//                 <div className="col">
//                     <div><a href='#'>Unselect all</a></div>
//                 </div>
//             </div>

//             {selectedConfig && <AppointmentConfiguration 
//                 configuration={selectedConfig} 
//                 durations={collection.durations} 
//                 repeatTypes={collection.repeatTypes}/>}
//         </div>
//     );
// }