import React, { useState } from 'react';
import IdentityStatus from './IdentityStatus';

function Layout(props) {
    const [navOpen, setNavOpen] = useState(false)

    return (
        <>
            <div className="tw-flex tw-flex-col tw-w-full tw-min-w-sm tw-h-screen">
                <header className='tw-bg-gradient-to-r tw-from-primary tw-to-slate-100'>
                    <nav className='navbar navbar-expand-lg'>
                        <a className='navbar-brand ms-4 tw-text-white tw-font-extrabold' href='/'>PremiumDoc</a>
                        <button className='navbar-toggler' type='button' aria-label='Toggle navigation' onClick={() => setNavOpen(!navOpen)}>
                            <span className='navbar-toggler-icon'></span>
                        </button>
                        <div className={`collapse navbar-collapse ${navOpen ? 'show' : ''}`} id='navbar_toggler'>
                            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                            </ul>
                            <ul className='navbar-nav'>
                                <IdentityStatus />
                            </ul>
                        </div>
                    </nav>
                </header>
                <main className='tw-flex tw-flex-col tw-h-full tw-w-full'>
                    {props.children}
                </main>
                <footer></footer>
            </div >
        </>
    );
}

export default Layout;