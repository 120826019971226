import ReservationCard from '../components/ReservationCard.js';

export default function Home() {
    return (
        <div className='tw-flex tw-flex-col tw-p-5 tw-min-h-full'>
            <div className="card tw-p-5 tw-flex lg:tw-h-full tw-flex-col lg:tw-flex-row tw-w-full tw-items-center tw-justify-evenly">
                <div className="tw-flex tw-flex-col tw-justify-center tw-h-[75%] tw-items-center">
                    <p className="tw-flex tw-w-full tw-text-5xl md:tw-text-6xl lg:tw-text-7xl xl:tw-text-8xl tw-text-nowrap tw-font-extrabold tw-pb-5">Your Health <br />On Your Timeline</p>
                    <p className="tw-flex tw-w-full tw-text-2xl tw-pb-24">PremiumDoc is the premier service for...</p>
                    <div className="tw-flex tw-justify-center tw-items-center">
                        <a href='/identity/signup' className='tw-flex btn btn-primary btn-lg'>Get Started</a>
                        <a href='/search' className='tw-flex btn btn-secondary btn-lg'>Find a specialist near you</a>
                    </div>
                </div>
                <div className="tw-w-full tw-flex tw-justify-center">
                    <ReservationCard className="tw-w-3/4 lg:tw-max-w-[600px]" headerText="Confirmed!" showHeaderUnderline={true} ptName="Jack Fetzer" drName="Dr. Fetzer" datetime="July 30th, 2024 8:30AM" location="Flemington, NJ" reason="Knee Consult" />
                </div>
            </div>
        </div>);
}