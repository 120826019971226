import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from "../components/Alert";
import { useState } from "react";
import { useApi } from "../security";
import BusyIndicator from "../components/BusyIndicator";

export default function Confirm(props){
    const api = useApi();
    const nav = useNavigate();

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [isBusy, setIsBusy] = useState(false);

    function cancel(){
        nav('/');
    }

    function verify(){
        setError(null);
        setSuccess(null);
        if (!code){
            setError('Verification code is required.');
            return;
        }
        if (!email){
            setError('Email address is required.');
            return;
        }
        setIsBusy(true);
        api.identity.confirm(email, code)
            .then(r =>{
                setIsBusy(false);
                if (r.data.success){
                    nav('/identity/login');
                } else{
                    setError(r.data.error);
                }
            }).catch(e => {
                setIsBusy(false);
                setError(e.toString());
            });
    }

    function resend(){
        setError(null);
        setSuccess(null);
        if (!email){
            setError('Email address is required.');
            return;
        }
        setIsBusy(true);
        api.identity.resend(email)
            .then(r =>{
                setIsBusy(false);
                if (r.data.success){
                    setSuccess('A new verification code has been sent. Please check your email.');
                } else{
                    setError(r.data.error);
                }
            }).catch(e => {
                setIsBusy(false);
                setError(e.toString());
            });
    }

    if (isBusy){
        return (<BusyIndicator/>);
    }

    return (<div>
        <Alert error={error} success={success}/>
        <div className="row">
            <div className="col-md-3"></div>
            <div className="col">
                <h3>Confirm email address</h3>
                <p>You should have recevied a verification code via email to confirm your email address. Please enter your email address the verifcation code below.</p>
                <p>If you did not receive a verification code or if an error message displays invalid code you may enter your email address to have a new code sent.</p>
                <div className="form-group">
                    <label>Email addresss</label>
                    <input type="email" className="form-control" name="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="form-group">
                    <label>Verification code</label>
                    <input type="text" className="form-control" name="code" value={code} onChange={e => setCode(e.target.value)}/>
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                    <button type="button" className="btn btn-clear ms-md-2" onClick={cancel}>Cancel</button>
                    <button type="button" className="btn btn-outline-primary" onClick={resend}>Resend Verification Code</button>
                    <button type="button" className="btn btn-primary btn-lg btn-125" onClick={verify}>Verify</button>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
    </div>);
}