export default function Card(props) {
    return (
        <div className={props.className + " card tw-m-10 tw-p-3 tw-flex tw-flex-col tw-items-center"}>
            {
                props.headerText != null ? (
                    <header className={`tw-flex tw-mb-5 tw-text-4xl tw-text-slate-600 tw-font-semibold tw-text-center ${props.showHeaderUnderline ? 'tw-border-primary tw-border-0 tw-border-b-[3px] tw-border-solid' : ''}`}>
                        {props.headerText}
                    </header>
                ) : (<></>)
            }

            {props.children}
        </div>
    )
}