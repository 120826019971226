import { useNavigate, useParams } from "react-router-dom";
import Alert from "../components/Alert";
import { useEffect, useState } from "react";
import BusyIndicator from "../components/BusyIndicator";
import { useApi } from "../security";
import Card from "../components/Card";

export default function Register(props) {
    const api = useApi();
    const nav = useNavigate();
    const parameters = useParams();
    const token = parameters.token;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });

    const [isEmailReadonly, setIsEmailReadonly] = useState(false);

    const [error, setError] = useState();
    const [isBusy, setIsBusy] = useState(false);

    function onCancel() {
        nav('/');
    }
    function onCreate() {
        if (!email) {
            setError('Email address is required');
            return;
        }
        if (!firstName) {
            setError('First name is required');
            return;
        }
        if (!lastName) {
            setError('Last name is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }
        if (!passwordConfirm) {
            setError('Confirm password is required');
            return;
        }
        if (password != passwordConfirm) {
            setError('Password and confirm password do not match');
            return;
        }
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => setCoords(pos.coords));
        }
        if (token) {
            setIsBusy(true);
            api.identity.registration.validate(token)
                .then(r => {
                    setIsBusy(false);
                    setEmail(r.data.email);
                    setFirstName(r.data.firstName);
                    setLastName(r.data.lastName);
                    setIsEmailReadonly(true);
                }).catch(e => setIsBusy(false));
        }
    }, []);

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (
        <div className="tw-flex tw-h-min tw-w-full tw-justify-center">
            <Card className="tw-w-min tw-h-min" headerText="Create a new account">
                <Alert error={error} />
                <div className="tw-flex">
                    <div className="tw-flex tw-flex-col tw-h-full tw-justify-around tw-m-4 tw-min-w-96">
                        <div className='tw-my-2 tw-flex tw-w-full tw-items-start'>
                            <div className="text-danger">
                                * Required
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col my-2">
                            <label>First name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" name='firstName' value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
                        </div>
                        <div className="tw-flex tw-flex-col my-2">
                            <label>Last name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" name='lastName' value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
                        </div>
                        <div className="tw-flex tw-flex-col my-2">
                            <label>Email address <span className='text-danger'>*</span></label>
                            <input type="email" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.currentTarget.value)} disabled={isEmailReadonly} />
                        </div>
                        <div className="tw-flex tw-flex-col my-2">
                            <label>Password <span className='text-danger'>*</span></label>
                            <input type="password" className="form-control" name='password' value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
                        </div>
                        <div className="tw-flex tw-flex-col my-2">
                            <label>Confirm password <span className='text-danger'>*</span></label>
                            <input type="password" className="form-control" name='passwordConfirm' value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.currentTarget.value)} />
                        </div>
                        <div>
                            Location: {coords.latitude}, {coords.longitude}
                        </div>
                        <div className="tw-flex tw-justify-around mt-4">
                            <button type="button" className="btn btn-clear ms-md-2" onClick={onCancel}>Cancel</button>
                            <button type="button" className="btn btn-primary btn-lg btn-125" onClick={onCreate}>Create Account</button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>);
}