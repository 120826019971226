import { useApi, useAuth } from "../security";
import { Tab, Tabs } from "./Navigation";
import { Roles, UserAccessScopes } from "../types";
import UserManagement from "./UserManagement";
import OnBoardingStatus from "./OnboardingStatus";
import AccountStatus from "./AccountStatus";

export default function AccountManagement(props){
    const api = useApi();
    const user = props.user || {};
    const scopes = props.scopes || [];
    const {auth, access} = useAuth();

    const tabProps = {auth, user, scopes}
    return (
        <Tabs>
            <Tab title='User Management' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.user.manage) && Roles.isDoctor(user.roles)}>
                <UserManagement {...tabProps}/>
            </Tab>
            <Tab title='Activation Status' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.user.admin)}>
                <OnBoardingStatus {...tabProps}/>
            </Tab>
            <Tab title='Account Status' visible={UserAccessScopes.canAccess(scopes, UserAccessScopes.user.admin)}>
                <AccountStatus {...tabProps}/>
            </Tab>
        </Tabs>
    );
}