import Card from "./Card";

export default function ReservationCard(props) {
    return (
        <Card className={props.className} headerText={props.headerText} showHeaderUnderline={props.showHeaderUnderline}>
            <div className="tw-flex tw-justify-center">
                <div className="card tw-flex-col tw-p-3 tw-m-8 tw-break-normal tw-min-w-min">
                    {props.ptName}
                </div>
                <img src="/arrow_right.svg" className="tw-w-[50px]" />
                <div className="card tw-flex tw-flex-col tw-p-3 tw-m-8 tw-break-normal tw-min-w-min">
                    {props.drName}
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-justify-around tw-px-10 tw-w-full">
                <div className="tw-flex tw-items-center tw-justify-left tw-my-3">
                    <img src="/clock.svg" />
                    <p className="tw-pl-3">{props.datetime}</p>
                </div>
                <div className="tw-flex tw-items-center tw-justify-left tw-my-3">
                    <img src="/map_pin.svg" />
                    <p className="tw-pl-3">{props.location}</p>
                </div>
                <div className="tw-flex tw-items-center tw-justify-left tw-my-3">
                    <img src="/info.svg" />
                    <p className="tw-pl-3">{props.reason}</p>
                </div>
            </div>
        </Card>
    )
}