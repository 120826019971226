import { useEffect, useState } from "react";
import { useApi, useAuth } from "../security";
import { UserAccessScopes, Roles } from "../types";
import Alert from "./Alert";
import BusyIndicator from './BusyIndicator';
import AccessList from "./AccessList";

function AddUser(props){
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState();

    function add(){
        if (!email){
            setError('Email address is required.');
            return;
        }
        if (!firstName){
            setError('First name is required.');
            return;
        }
        if (!lastName){
            setError('Last name is required.');
            return;
        }

        props?.add(email, firstName, lastName);
    }

    return (
        <>
            <Alert error={error}/>
            <div className="row">
                <div className="col">
                    <label>Email address</label>
                    <input type="email" name="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="col">
                    <label>First name</label>
                    <input type="text" name="firstName" className="form-control" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                </div>
                <div className="col">
                    <label>Last name</label>
                    <input type="text" name="lastName" className="form-control" value={lastName} onChange={e => setLastName(e.target.value)}/>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-auto ms-auto">
                    <button className="btn btn-secondary me-2" onClick={props.cancel}>Cancel</button>
                    <button className="btn btn-primary" onClick={add}>Add User</button>
                </div>
            </div>
        </>
    )
}

export default function UserManagement(props){
    const api = useApi();
    const {access} = useAuth();
    const user = props.user || {};
    const scopes = props.scopes || [];

    const [result, setResult] = useState();
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    function showAddUser(){
        setIsAddingUser(true);
    }

    function addUser(email, firstName, lastName){
        setIsAddingUser(false);
        setIsBusy(true);
        api.users.add({
            userId:user.id,
            organizationId:user.organization?.id,
            email,
            firstName,
            lastName
        }).then(r =>{
            setIsBusy(false);
            load();
        }).catch(e => {
            setError(e.toString());
            setIsBusy(false);
        });
    }

    function load(){
        if (user){
            api.users.access.load(user.id)
                .then(r =>{
                    setResult(r.data);
                    console.log('USER_MGMT:',r.data);
                }).catch(e => {});
        }
    }

    function updateAccess(userId, key, value){
        if (result){
            const user = result.users.find(o => o.id === userId);
            if (user){
                const index = user.scopes.indexOf(key);
                if (!value && index >= 0){
                    // remove the key
                    user.scopes.splice(index, 1);
                } else if (value && index < 0){
                    user.scopes.push(key);
                }
            }
            console.log('USER_MGMT: updating access:',{user});
        }
    }

    function saveUser(id){
        if (!result){
            return;
        }

        const editUser = result.users.find(o => o.id === id);

        setIsBusy(true);
        api.users.access.update({
            primaryUserId: user.id,
            linkedUserId: editUser?.id,
            scopes:editUser?.scopes
        })
            .then(r =>{
                setSuccess('User updated sucessfully.');
                setIsBusy(false);
            }).catch(e =>{
                setError(e.toString());
                setIsBusy(false);
            });
    }

    function deleteUser(id){
        if (!result){
            return;
        }

        if (window.confirm('Removing this user will unlink them from the current account. Are you sure you wish to continue and unlink this user?')){
            setIsBusy(true);
            api.users.access.remove(user.id, id)
                .then(r =>{ 
                    setIsBusy(false);
                    setSuccess('User removed sucessfully.');
                    load();
                }).catch(e => {
                    setIsBusy(false);
                    setError(e.toString());
                });
        }
    }

    useEffect(load, [props.user]);

    if (isBusy){
        return (<BusyIndicator/>);
    }

    if (isAddingUser){
        return (<AddUser add={addUser} cancel={() => setIsAddingUser(false)}/>);
    }

    return (<>
        <Alert error={error} success={success}/>
        {UserAccessScopes.canAccess(scopes, UserAccessScopes.user.admin) 
        && (<div className="row">
                <div className="col-auto ms-auto">
                    <button className="btn btn-success" onClick={showAddUser}>Add User</button>
                </div>
            </div>)}
        <div className="row">
            <div className="col">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Email address</th>
                            <th>Name</th>
                            <th>Permissions</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(result?.users || []).map((u,i) => (<tr key={i}>
                            <td>{u.email}</td>
                            <td>{u.name}</td>
                            <td><AccessList user={u} scopes={result.scopes} update={updateAccess}/></td>
                            <td><button className="btn btn-success" onClick={e => saveUser(u.id)}>Update</button></td>
                            <td><button className="btn btn-danger" onClick={e => deleteUser(u.id)}>Remove</button></td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
        </div>
    </>);
}