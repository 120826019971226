import React, { useState, useEffect, useMemo } from 'react';
import './Register.css';
import ApiService from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import BusyIndicator from '../../components/BusyIndicator';
import SpecialtySelector from '../../components/SpecialtySelector';
import { useApi } from '../../security';
import Modal from '../../components/Modal';
import Card from '../../components/Card';

function Practice(props) {
    const specialties = props.specialties || [];
    const territories = props.territories
    const [group, setGroup] = useState('');
    const [city, setCity] = useState('');
    const [territory, setTerritory] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [subSpecialties, setSubSpecialties] = useState([]);

    const [error, setError] = useState();

    //const subSpecialties = useMemo(() => [],[]);

    function onNext() {
        if (subSpecialties.length === 0) {
            setError("Specialty is required.");
            return;
        }
        if (!group) {
            setError("Medical Group is required.");
            return;
        }
        if (!city) {
            setError("City is required.");
            return;
        }
        if (!territory) {
            setError("State is required.");
            return;
        }
        props?.onNext({
            specialties: subSpecialties.map(o => o.id),
            medicalGroup: group,
            city,
            territory,
            postalCode
        });
    }

    // function onSubSpecialtyChanged(spec, sub, checked){
    //     console.log('specialty changed: ' + sub.id + ' - ' + checked);
    //     if (subSpecialties.includes(sub.id) && !checked){
    //         const index = subSpecialties.indexOf(sub.id);
    //         if (index >= 0){
    //             subSpecialties.splice(index, 1);
    //         }
    //     }else if(checked){
    //         subSpecialties.push(sub.id);
    //     }
    //     console.log(subSpecialties);
    // }

    function updateSpecialties(specialties) {
        setSubSpecialties(specialties);
    }

    return (
        <div className="tw-flex tw-h-min tw-w-full tw-justify-center">
            <Card className="tw-w-min tw-h-min" headerText="Tell us about your practice?">
                {error && <div className='alert alert-danger'>{error}</div>}
                <div className="tw-flex">
                    <div className="tw-flex tw-flex-col tw-h-full tw-justify-around tw-m-4 tw-min-w-96">
                        <div className='tw-my-2 tw-flex tw-w-full tw-items-start'>
                            <div className="text-danger">
                                * Required
                            </div>
                        </div>
                        <SpecialtySelector selected={subSpecialties} update={updateSpecialties} />
                        {
                            /* <div>Specialties <span className='text-danger'>*</span></div>
                            {
                                (specialties || []).map((s, i) => <Specialty value={s} key={i} checkChanged={onSubSpecialtyChanged}/>)
                            } */
                        }
                        {
                            /* <div className="col">
                            <label>Specialty <span className='text-danger'>*</span></label>
                            <select className="form-select" name='specialty' value={specialty} onChange={(e) => props.onSpecialtyChanged(e.currentTarget.value)}>
                                <option value=''></option>
                                {(specialties || []).map((s,i) => <option key={i} value={s.id}>{s.name}</option>)}
                            </select>
                            </div>
                            <div className="col">
                                <label>Subspecialty <span className='text-danger'>*</span></label>
                                <select className="form-select" name='subSpecialty' value={subSpecialty} onChange={(e) => setSubSpecialty(e.currentTarget.value)}>
                                    <option value=''></option>
                                    {(subSpecialties || []).map((s,i) => <option key={i} value={s.id}>{s.name}</option>)}
                                </select>
                            </div> */
                        }
                        <div className="tw-flex">
                            <div className="tw-flex tw-flex-col tw-w-full">
                                <label>Medical Group <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" name='group' value={group} onChange={(e) => setGroup(e.currentTarget.value)} />
                            </div>
                        </div>
                        <div className="tw-flex tw-mt-2 max-sm:tw-flex-col">
                            <div className="tw-flex tw-flex-col sm:tw-pr-2 tw-min-w-48">
                                <label>City <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" name='city' value={city} onChange={(e) => setCity(e.currentTarget.value)} />
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-px-2">
                                <label>State <span className='text-danger'>*</span></label>
                                <select className="form-select" name='territory' value={territory} onChange={(e) => setTerritory(e.currentTarget.value)}>
                                    <option value=''></option>
                                    {(territories || []).map((s, i) => <option key={i} value={s.code}>{s.name}</option>)}
                                </select>
                            </div>
                            <div className="tw-flex tw-flex-col sm:tw-pl-2 tw-min-w-24">
                                <label>Postal Code</label>
                                <input type="text" className="form-control" name='postalCode' value={postalCode} onChange={(e) => setPostalCode(e.currentTarget.value)} />
                            </div>
                        </div>

                        <div className="tw-flex tw-justify-around tw-mt-4 tw-items-center">
                            <button type="button" className="btn btn-secondary btn-lg btn-125" onClick={props?.onBack}>Back</button>
                            <button type="button" className="btn btn-primary btn-lg btn-125" onClick={onNext}>Next</button>
                        </div>

                    </div>
                </div>
            </Card>
        </div>
    );
}

function User(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [error, setError] = useState();

    function onNext() {
        if (!firstName) {
            setError("First name is required.");
            return;
        }
        if (!lastName) {
            setError("Last name is required.");
            return;
        }
        if (!email) {
            setError("Email address is required.");
            return;
        }
        props?.onNext({
            firstName,
            lastName,
            email,
            phone
        });
    }

    return (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
            <Card className="tw-w-4/5 lg:tw-w-3/5 tw-h-min" headerText="Tell us about your yourself?">
                {error && <div className='alert alert-danger'>{error}</div>}
                <div className='text-danger mt-2 mb-2'>
                    * Required
                </div>

                <div className="tw-flex tw-flex-col tw-w-full">
                    <div className='tw-flex tw-justify-around'>
                        <div className='tw-w-2/5'>
                            <label>First Name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" name='firstName' value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} />
                        </div>
                        <div className='tw-w-2/5'>
                            <label>Last Name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control" name='lastName' value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} />
                        </div>
                    </div>
                    <div className='tw-flex tw-justify-around'>
                        <div className='tw-w-2/5'>
                            <label>Email Address <span className='text-danger'>*</span></label>
                            <input type="email" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                        </div>
                        <div className='tw-w-2/5'>
                            <label>Phone Number</label>
                            <input type="text" className="form-control" name='phone' value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
                        </div>
                    </div>
                </div>

                <div className="tw-flex tw-m-5">
                    <button type="button" className="btn btn-clear ms-md-2" onClick={props?.onCancel}>Cancel</button>
                    <button type="button" className="btn btn-secondary btn-lg btn-125" onClick={props?.onBack}>Back</button>
                    <button type="button" className="btn btn-primary btn-lg btn-125" onClick={onNext}>Next</button>
                </div>
            </Card>
        </div>);
}

function Contact(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState();

    const [isContactScheduler, setIsContactScheduler] = useState(false);

    function onNext() {
        if (!name) {
            setError("Contact name is required.");
            return;
        }
        if (!email && !phone) {
            setError("Contact email address or phone number is required.");
            return;
        }
        props?.onNext({
            contactName: name,
            contactEmail: email,
            contactPhone: phone
        });
    }

    function onCancel() {
        setIsContactScheduler(false);
    }

    function onContactMe() {
        props?.onNext({});
    }
    function onContactScheduler() {
        setIsContactScheduler(true);
    }

    return (<div>
        {error && <div className='alert alert-danger'>{error}</div>}

        {isContactScheduler &&
            (
                <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
                    <Card className="tw-w-4/5 lg:tw-w-3/5 tw-h-min">
                        <div className='text-danger mt-2 mb-2'>
                            * Required
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Contact Name <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" name='contactname' value={name} onChange={(e) => setName(e.currentTarget.value)} />
                            </div>
                            <div className="col">
                                <label>Contact email address</label>
                                <input type="email" className="form-control" name='contactemail' value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                            </div>
                            <div className="col">
                                <label>Contact phone number</label>
                                <input type="text" className="form-control" name='contactphone' value={phone} onChange={(e) => setPhone(e.currentTarget.value)} />
                            </div>
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                            <button type="button" className="btn btn-clear ms-md-2" onClick={onCancel}>Cancel</button>
                            <button type="button" className="btn btn-secondary btn-lg btn-125" onClick={props?.onBack}>Back</button>
                            <button type="button" className="btn btn-primary btn-lg btn-125" onClick={onNext}>Next</button>
                        </div>
                    </Card>
                </div>
            )}

        {!isContactScheduler &&
            (
                <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
                    <Card className="tw-w-fit " headerText="Finishing Up">
                        <p>PremiumDoc needs to contact you to finish registration.</p>
                        <p>Who is the best person to complete the onboarding process?</p>

                        <div className='tw-flex tw-my-5'>
                            <button className="btn btn-outline-primary me-2" onClick={onContactMe}>Contact Me</button>
                            <button className="btn btn-outline-primary ms-2" onClick={onContactScheduler}>Contact Scheduler</button>
                        </div>
                    </Card>
                </div>
            )}
    </div>);
}

function LabelRow(props) {
    return (<div className='tw-flex tw-w-full'>
        <div className='col-5 text-right fw-bold'>
            {props.title}
        </div>
        <div className='col'>
            {props.value}
        </div>
    </div>);
}

function Review(props) {
    const practice = props.practice || {};
    const user = props.user || {};
    const contact = props.contact || {};
    const specialties = props.specialties || [];

    const specialty = specialties.find(o => o.parentId === null && o.subSpecialties.some(s => practice.specialties.includes(s.id)));

    let specialtyList = specialty?.name + ': ';
    for (let index = 0; index < practice.specialties.length; index++) {
        const spec = specialty.subSpecialties.find(o => o.id === practice.specialties[index]);
        if (spec) {
            specialtyList += (index === 0 ? spec.name : ', ' + spec.name);
        }
    }

    return (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center">

            <Card className='tw-w-fit tw-h-min' headerText="Review your account information">
                <h5 className='tw-my-3 tw-font-semibold'>Account Details</h5>
                <LabelRow title='Name:' value={(user.firstName + ' ' + user.lastName)} />
                <LabelRow title='Email address:' value={user.email} />
                <LabelRow title='Phone number:' value={user.phone} />
                <h5 className='tw-mb-3 tw-mt-10 tw-font-semibold'>Practice</h5>
                <LabelRow title='Specialties:' value={specialtyList} />
                <LabelRow title='Medical group:' value={practice.medicalGroup} />
                <LabelRow title='Location:' value={(practice.city + ', ' + practice.territory + ' ' + practice.postalCode)} />
                {contact.contactName && (
                    <>
                        <h5 className='mt-3'>Contact</h5>
                        <LabelRow title='Name:' value={contact.contactName} />
                        <LabelRow title='Email address:' value={contact.contactEmail} />
                        <LabelRow title='Phone number:' value={contact.contactPhone} />
                    </>
                )}
                <div className="tw-flex tw-justify-center tw-items-center tw-mt-10">
                    <button type="button" className="btn btn-clear ms-md-2" onClick={props?.onCancel}>Cancel</button>
                    <button type="button" className="btn btn-secondary btn-lg btn-125" onClick={props?.onBack}>Back</button>
                    <button type="button" className="btn btn-primary btn-lg btn-125" onClick={props?.onNext}>Next</button>
                </div>
            </Card>
        </div>
    );
}

function Complete(props) {
    return (<div className='alert alert-success'>
        <p>Thank you for creating a PremiumDoc account. The person specified as the contact will receive a phone call from a PremiumDoc associate within the next business day to complete registration.</p>
        <p>You will also receive an email requiring you to verify your email address and confirm your registration.</p>
        <div className='d-flex justify-content-center mt-4'>
            <a href='/' className='btn btn-primary'>Return to home page</a>
        </div>
    </div>);
}

function Failed(props) {
    return (<div className='alert alert-danger'>
        <p>An error has prevented the process from completing. Support has been notified of the issue. If information was saved a support associate will contact you to finish registration.</p>
    </div>)
}

export default function Register(props) {
    const api = useApi();
    const nav = useNavigate();

    const steps = {
        practice: 0,
        user: 1,
        contact: 2,
        review: 3,
        complete: 4,
        failed: 5
    };

    const [specialties, setSpecialties] = useState([]);
    const [territories, setTerritories] = useState([]);

    const [practice, setPractice] = useState();
    const [user, setUser] = useState();
    const [contact, setContact] = useState();
    const [step, setStep] = useState(steps.practice);
    const [isBusy, setIsBusy] = useState(true);

    const [showCancel, setShowCancel] = useState(false);
    const [cancelled, setCancelled] = useState(false);

    function create(data) {
        api.identity.registration.doctor(data)
            .then(r => {
                setIsBusy(false);
                setStep(steps.complete);
            })
            .catch(e => {
                setIsBusy(false);
                setStep(steps.failed);
            });
    }

    function onNext(data) {
        if (step === steps.practice) {
            setPractice(data);
            setStep(steps.user);
        } else if (step === steps.user) {
            setUser(data);
            setStep(steps.contact);
        } else if (step === steps.contact) {
            setContact(data);
            setStep(steps.review);
        } else if (step === steps.review) {
            setIsBusy(true);
            let obj = {};
            Object.assign(obj, practice);
            Object.assign(obj, user);
            Object.assign(obj, contact);
            create(obj);
        }
    }

    function onBack() {
        if (step === steps.practice) {
            onCancel();
            return;
        }
        setStep(step - 1);
    }

    function onCancel() {
        setShowCancel(true);
    }

    function onCancelConfirmed() {
        setShowCancel(false);
        setCancelled(true);
    }

    useEffect(() => {
        api.common.specialties().then(r => {
            setSpecialties(r.data);
            api.common.territories().then(r => {
                setTerritories(r.data);
                setIsBusy(false);
            });
        });
    }, []);

    useEffect(() => {
        if (cancelled) {
            nav('/');
        }
    }, [cancelled]);

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (
        <>
            {([step].map((s, i) => {
                if (s === steps.user) {
                    return (<User key={i} onCancel={onCancel} onNext={onNext} onBack={onBack} />);
                } else if (s === steps.contact) {
                    return (<Contact key={i} onCancel={onCancel} onNext={onNext} onBack={onBack} />);
                } else if (s === steps.review) {
                    return (<Review key={i}
                        practice={practice}
                        user={user}
                        contact={contact}
                        specialties={specialties}
                        onCancel={onCancel}
                        onNext={onNext}
                        onBack={onBack} />);
                } else if (s === steps.complete) {
                    return (<Complete key={i} />);
                } else if (setContact === steps.failed) {
                    return (<Failed key={i} />);
                }
                return (<Practice key={i} api={api}
                    onCancel={onCancel}
                    onNext={onNext}
                    onBack={onBack}
                    specialties={specialties}
                    territories={territories} />);
            }))}
            <Modal show={showCancel} title='Cancel Registration?' onAffirm={onCancelConfirmed} onCancel={() => setShowCancel(false)} affirmTitle='Yes' cancelTitle='No'>
                <p>Are you sure you want to cancel registration, all entered information will be lost?</p>
            </Modal>
        </>
    );
}