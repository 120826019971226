import { useEffect, useState } from "react";
import { useApi, useAuth } from "../security";
import Alert from "../components/Alert";
import BusyIndicator from "../components/BusyIndicator";
import Card from "../components/Card";

export default function HomeSchedulers(props) {
    const api = useApi();
    const { auth } = useAuth();

    const [results, setResults] = useState();
    const [isBusy, setIsBusy] = useState(false);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    function load() {
        if (auth) {
            setIsBusy(true);
            api.users.list(auth.id)
                .then(r => {
                    setIsBusy(false);
                    setResults(r.data);
                    console.log('USER_MGMT:', r.data);
                }).catch(e => {
                    setIsBusy(false);
                    setError(e.toString());
                });
        }
    }

    useEffect(load, []);

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (<>
        <Alert error={error} success={success} />
        <div className="tw-w-full tw-h-fit tw-p-5">
            <Card className="tw-h-full tw-w-full tw-m-0">
                <table className="table tw-max-w-full">
                    <thead>
                        <tr>
                            <th>Email address</th>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(results || []).map((r, i) => (<tr key={i}>
                            <td>{r.primaryUser.email}</td>
                            <td>{r.primaryUser.fullName}</td>
                            <td><a className="btn btn-primary" href={('/users/edit/' + r.primaryUser.id)}>View</a></td>
                        </tr>))}
                    </tbody>
                </table>
            </Card>
        </div>
    </>);
}