import { useState } from "react";

function AccessRow(props){
    const scope = props.scope;
    const userKey = props.userKey;
    
    const [checked, setChecked] = useState(userKey !== null && userKey !== undefined && userKey !== '');

    function updateState(){
        const newValue = !checked;
        setChecked(newValue);
        props?.update(scope.key, newValue ? scope.key : null);
    }

    return (<tr>
        <td>{scope.name}</td>
        <td className="text-center"><input type="checkbox" className="form-check-input" checked={checked} onChange={updateState}/></td>
    </tr>);
}

export default function AccessList(props){
    const user = props.user;
    const scopes = props.scopes || [];

    function onUpdate(key, value){
        props?.update(user.id, key, value);
    }

    return (<div>
        <table className="table table-striped">
            <tbody>
                {(scopes || []).map((a,i) => (<AccessRow key={i} scope={a} userKey={user.scopes.find(o => o === a.key)} update={onUpdate} />))}
            </tbody>
        </table>
    </div>);
}