import { useEffect, useState } from "react";
import { useApi, useAuth } from "../security";
import Switch from "./Switch";
import { useObjectState } from "../hooks";
import Alert from "./Alert";
import SearchOrganizations from "./SearchOrganizations";
import { OrganizationEditor } from "./OrganizationManagement";

const OrgSelectionState = {
    default: 0,
    search: 1,
    add: 2
};

function OrganizationRow(props) {
    const organization = props.organization;
    const hasParent = organization.parentId && organization.parentId !== null && organization.parentId !== '';
    const [isSelected, setIsSelected] = useObjectState(organization, 'isSelected');

    return (
        <>
            <tr>
                {!hasParent && <td colSpan="2">{organization.name}</td>}
                {hasParent && (
                    <>
                        <td style={{ minWidth: '25px' }}></td>
                        <td>{organization.name}</td>
                    </>
                )}
                <td>{organization.city}, {organization.territory} {organization.postalCode1}</td>
                <td className="text-center">
                    <Switch checked={isSelected} onCheckChanged={value => setIsSelected(value)} />
                </td>
            </tr>
            {(organization.locations || []).map((loc, idx) => {
                const index = (props.index || 0) + '_' + idx;
                return (<OrganizationRow key={index} index={index} organization={loc} />);
            })}
        </>
    );
}

export default function OrganizationSelector(props) {
    const api = useApi();
    const user = props.user;
    const scopes = props.scopes;
    const { auth } = useAuth();

    const [state, setState] = useState(OrgSelectionState.default);
    const [newOrg, setNewOrg] = useState({});
    const [territories, setTerritories] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState({});
    const [searchResults, setSearchResults] = useState([]);
    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    function setApiError(msg) {
        setIsBusy(false);
        setError(msg);
        setSuccess('');
    }

    function onCancel() {
        setError('');
        setState(OrgSelectionState.default);
    }

    function onAdd() {
        setError('');
        setState(OrgSelectionState.add);
        setNewOrg({});
    }

    function onOrganizationSelected(org) {
        setIsBusy(true);
        org.isSelected = true;
        api.users.organizations.add(user.id, org)
            .then(r => {
                setIsBusy(false);
                setSuccess('Added medical group/practice.');
                load();
            }).catch(e => {
                setApiError('Could not add the selected organization.');
                load();
            });
    }

    function onUpdate() {
        setError('');
        setIsBusy(true);
        console.log('ORGS:', organizations);
        api.users.organizations.update(user.id, organizations)
            .then(r => {
                setIsBusy(false);
                setSuccess('Medical groups/pratices updated successfully.');
                load();
            }).catch(e => setApiError('Could not update medical groups.'));
    }

    function onSearch(criteria) {
        setError('');
        if (!criteria.territory) {
            setError('State/Territory is required.');
            return;
        }

        setSearchCriteria(criteria);
        setIsBusy(true);
        api.organizations.search(criteria)
            .then(r => {
                setIsBusy(false);
                setSearchResults(r.data);
            }).catch(e => setApiError('Could not execute search.'));
    }

    function onSaveOrganization() {
        console.log('ORGS: Save org:', newOrg);
        setError('');
        setSuccess('');
        newOrg.isSelected = true;
        if (!newOrg.name) {
            setError('Primary office name is required.');
            return;
        }
        if (!newOrg.postalCode1) {
            if (!newOrg.city && !newOrg.territory) {
                setError('Primary office location city and state or postal code are required.');
                return;
            }
        }
        setIsBusy(true);
        api.organizations.save(newOrg)
            .then(r => {
                onOrganizationSelected(r.data);
            }).catch(e => setApiError('Could not save medical group.'));
    }

    function load() {
        setState(OrgSelectionState.default);
        setOrganizations([]);
        api.users.organizations.list(user.id)
            .then(r => {
                setOrganizations(r.data);
            }).catch(e => { });
    }

    useEffect(() => {
        load();
        api.common.territories()
            .then(r => setTerritories(r.data));
    }, []);

    return (
        <>
            <Alert error={error} success={success} />
            <h3>Medical Groups/Practices</h3>

            {state === OrgSelectionState.search && (
                <SearchOrganizations
                    selectionLabel='Select'
                    territories={territories}
                    criteria={searchCriteria}
                    results={searchResults}
                    allowCancel={true}
                    onCancel={onCancel}
                    onSelected={onOrganizationSelected}
                    onSearch={onSearch}
                    onAdd={onAdd} />
            )}

            {state === OrgSelectionState.add && (
                <OrganizationEditor
                    isAdding={true}
                    organization={newOrg}
                    territories={territories}
                    setError={setError}
                    onCancel={onCancel}
                    onSave={onSaveOrganization} />
            )}

            {state === OrgSelectionState.default && (
                <div className="tw-flex tw-flex-col tw-w-full tw-mt-5">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th colSpan="2">Name</th>
                                <th>Location</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(organizations || []).map((org, idx) => {
                                return (<OrganizationRow key={idx} index={idx} organization={org} />);
                            })}
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <div className="tw-flex">
                            <button className="btn btn-primary" onClick={() => setState(OrgSelectionState.search)}>Add Medical Group(s)</button>
                            <button className="btn btn-lg btn-primary" onClick={onUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}