import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppointmentSearchResults from "../components/AppointmentSearchResults";
import BusyIndicator from "../components/BusyIndicator";
import { addDays } from "../helpers";
import ApiService from "../services/ApiService";
import { useApi } from "../security";
import Card from "../components/Card";

function SearchLocation(props) {

    const [error, setError] = useState();
    const [hasGeolocation, setHasGeolocation] = useState(false);
    const [location, setLocation] = useState('');
    const [coords, setCoords] = useState(null);
    const [loc, setLoc] = useState(null);

    function useLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((pos) => {
                setCoords({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });
                setLoc('Current location: ' + pos.coords.latitude + ', ' + pos.coords.longitude);
            });
        }
    }

    function next() {
        if ((location === '' || location === null || location === undefined)
            && coords === null) {
            setError('Please enter your postal code or allow your location to be retrieved.');
            return;
        }
        props.onNext(location, coords);
    }

    useEffect(() => {
        if ('geolocation' in navigator) {
            setHasGeolocation(true);
        }
    }, []);

    return (<Card className="tw-h-full" headerText="Let's start with your location">
        {error && <div className="alert alert-danger">{error}</div>}
        <Card className="tw-h-3/5 tw-w-4/5 tw-justify-around tw-items-center tw-max-w-2xl">
            <div className="tw-flex tw-h-2/5 tw-w-1/2 tw-flex-col tw-justify-around">
                <input className="form-control" placeholder="Postal code" value={location} onChange={e => setLocation(e.target.value)} />
                {hasGeolocation && <>
                    <p className="text-center">OR</p>
                    <button className="btn btn-success" onClick={useLocation}>Use my current location</button>
                    {loc && <p>{loc}</p>}
                </>}
            </div>
            <button className="btn btn-primary btn-lg tw-mt-4 tw-px-20" onClick={next}>Next</button>
        </Card>
    </Card>);
}

function SearchSpecialty(props) {

    function next(id) {
        props.onNext(id, props?.location, props?.coords);
    }

    return (<Card className="tw-h-full" headerText="Next, let's identify the Specialty you're looking for">
        <Card className="tw-h-1/5 tw-w-4/5 tw-justify-around tw-items-center tw-max-w-2xl">
            <div className="tw-flex">
                {(props?.specialties || []).map((o, i) => <button key={i} className="btn btn-primary btn-lg ms-2 me-2" onClick={e => next(o.id)}>{o.name}</button>)}
                <button key='other' className="btn btn-primary btn-lg ms-2 me-2" onClick={e => next('00000000-0000-0000-0000-000000000000')}>Other</button>
            </div>
        </Card>
    </Card>);
}


export default function Search() {
    const api = useApi();
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isBusy, setIsBusy] = useState(true);
    const [error, setError] = useState();
    const [results, setResults] = useState();
    const [subSpecialties, setSubSpecialties] = useState([]);

    let specialties = [];

    function onLocationSelected(location, coords) {
        setContent(<SearchSpecialty specialties={specialties} location={location} coords={coords} onNext={onSpecialtySelected} />);
    }

    function onSpecialtySelected(id, location, coords) {
        if (!id) {
            return;
        }
        requery(id, '', location, coords != null ? coords.latitude : 0, coords != null ? coords.longitude : 0);
    }

    function onSubSpecialtySelected(id) {
        if (!id) {
            return;
        }
        requery(searchParams.get('specialty'), id, searchParams.get('location') || '', searchParams.get('lat') || 0, searchParams.get('lng') || 0);
    }

    function requery(specialty, subSpeciality, location, latitude, longitude) {
        const url = encodeURI('/search?startDate=' + addDays(new Date(), 1).toISOString() + "&specialty=" + specialty + '&subSpeciality=' + subSpeciality + '&location=' + location + '&lat=' + latitude + '&lng=' + longitude);
        window.location.href = url;
    }

    function doSearch() {
        setIsBusy(true);

        if (!searchParams.get('specialty')) {
            setIsBusy(false);
            return;
        }

        api.appointments.search(
            {
                startDate: new Date(searchParams.get('startDate') || addDays(new Date(), 1)),
                specialty: searchParams.get('specialty') || '',
                subSpeciality: searchParams.get('subSpeciality') || '',
                location: searchParams.get('location') || '',
                latitude: searchParams.get('lat') || 0,
                longitude: searchParams.get('lng') || 0
            })
            .then(r => {
                setResults(r.data);
                setIsBusy(false);
            }).catch(e => {
                setError(e.toString());
                setIsBusy(false);
            });
    }

    function onAppointmentSelected(doctor, appointment) {
        appointment.doctor = doctor;
        nav('/reserve', { state: { doctor, appointment } });
    }

    const [content, setContent] = useState(<SearchLocation onNext={onLocationSelected} />);

    useEffect(() => {
        if (searchParams.get('specialty')) {
            api.common.specialties(searchParams.get('specialty'))
                .then(r => {
                    setSubSpecialties(r.data);
                });
            doSearch();
        } else {
            if (specialties.length === 0) {
                api.common.specialties().then(r => {
                    specialties = r.data;
                    setIsBusy(false);
                });
            }
        }
    }, []);

    if (isBusy) {
        return <BusyIndicator />
    }

    if (results) {
        return (
            <Card className="tw-h-full tw-min-h-min">
                <div className="tw-min-h-min tw-w-full">
                    <AppointmentSearchResults
                        results={results}
                        subSpecialties={subSpecialties}
                        selectedSubSpecialty={searchParams.get('subSpeciality') || ''}
                        onFilter={onSubSpecialtySelected}
                        onAppointmentSelected={onAppointmentSelected} />
                </div>
            </Card>);
    }

    return (<>
        {error && <div className="alert alert-danger">{error}</div>}
        {content}
    </>);
}