import { useEffect, useState } from "react";
import { useApi } from "../security";
import BusyIndicator from "./BusyIndicator";
import Alert from "./Alert";
import AppointmentConfiguration from "./AppointmentConfiguration";
import { addDays, getStartOfWeek, groupBy } from "../helpers";
import AppointmentCalendar from "./AppointmentCalendar";

export default function Scheduling(props){
    const api = useApi();
    const user = props.user || {};

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();

    const [repeatTypes, setRepeatTypes] = useState([]);
    const [durations, setDurations] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState();
    const [config, setConfig] = useState();
    const [searchResults, setSearchResults] = useState();
    const [selectedDate, setSelectedDate] = useState();

    function onOrganizationChanged(id){
        setSelectedOrg(orgs.filter(o => o.id === id)[0]);
    }
    function onRevertToDefault(){
        api.appointments.reset(user.id, selectedOrg?.id).then(r => load(r.data));
    }
    function onConfigChanged(action, data){
        api.appointments.update(user.id, selectedOrg?.id, config.id, action, data)
            .then(r => load(r.data));
    }

    function load(value){
        setConfig(value);
        search(selectedDate);
    }

    function search(dt){
        const start = getStartOfWeek(dt);
        const end = addDays(start, 6);
        api.appointments.schedule({
                doctorId:user.id,
                organizationId:selectedOrg?.id,
                startDate:start.toISOString(),
                endDate:end.toISOString()    
            })
            .then(r => {
                setSearchResults(r.data);
            });
    }

    useEffect(() =>{
        api.appointments.data(user.id)
            .then(r =>{
                console.log('SCHEDULING: ', r.data);
                setRepeatTypes(r.data.repeatTypes);
                setDurations(r.data.durations);
                setOrgs(r.data.organizations);

                const org = r.data.organizations[0];
                setSelectedOrg(org);
            }).catch(e => {});
    },[]);

    useEffect(() =>{
        if (!selectedDate)
            return;

        if (!selectedOrg)
            return;
        search(selectedDate);        
    },[selectedDate]);

    useEffect(() =>{
        if (!selectedOrg)
            return;

        api.appointments.config(user.id, selectedOrg.id)
            .then(r => load(r.data)).catch(e => {});

        if (selectedDate){
            search(selectedDate);
        }
    },[selectedOrg]);

    if (isBusy){
        return (<BusyIndicator/>);
    }
    
    return(<div>
        <Alert error={error}/>

        <h3>Configure availability to accept patients</h3>

        <div className="row mt-4 panel">
            <div className="col-3">
                <div className="p-2">
                <AppointmentConfiguration 
                        configuration={config} 
                        repeatTypes={repeatTypes} 
                        durations={durations}
                        organization={selectedOrg}
                        organizations={orgs}
                        onOrganizationChanged={onOrganizationChanged}
                        onRevertToDefault={onRevertToDefault}
                        onConfigChanged={onConfigChanged} />
                </div>
                <div className="p-2 d-flex justify-content-end">
                    {/* <button className="btn btn-primary">Share</button> */}
                </div>
            </div>
            <div className="col p-4">
            <AppointmentCalendar results={searchResults} onDateChange={dt => setSelectedDate(dt)}/>
            </div>
        </div>
    </div>);
}