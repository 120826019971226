import { useState } from "react";

export default function Switch(props){
    const id = (props.id || '__switch__') + (new Date()).getTime();
    const onText = props.onText || props.label || '';
    const offText = props.offText || props.label || '';

    const [checked, setChecked] = useState(props.checked);
    const [label, setLabel] = useState(props.checked ? onText : offText);

    function onCheckChanged(){
        const newValue = !checked;
        setChecked(newValue);
        setLabel(newValue ? onText : offText);
        props?.onCheckChanged(newValue);
    }

    return (
        <div className="form-check form-switch">
            <input type="checkbox" id={id} className="form-check-input" role="switch" checked={checked} onChange={onCheckChanged}/>
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </div>
    );
}