import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import BusyIndicator from "../components/BusyIndicator";
import { useApi } from "../security";
import Card from "../components/Card";

export default function ForgotPassword() {
    const api = useApi();
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [emailSent, setEmailSent] = useState(searchParams.get('email')?.length > 0 || false);
    const [code, setCode] = useState(searchParams.get('code') || '');
    const [pwd, setPwd] = useState('');
    const [pwdConfirm, setPwdConfirm] = useState('');
    const [complete, setComplete] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    function send() {
        if (email?.length === 0) {
            setError('Email address is required.');
            return;
        }

        setIsBusy(true);
        api.identity.forgotPassword(email)
            .then(r => {
                setEmailSent(true);
                setIsBusy(false);
            }).catch(e => {
                setError(e?.response?.data ?? e?.message);
                setIsBusy(false);
            });
    }

    function update() {
        if (code?.length === 0) {
            setError('Code is required.');
            return;
        }
        if (pwd?.length === 0) {
            setError('New Pasword is required.');
            return;
        }
        if (pwdConfirm?.length === 0) {
            setError('Confirm New Password is required.');
            return;
        }
        if (pwd !== pwdConfirm) {
            setError('Password and confirm password must be the same.');
            return;
        }

        setIsBusy(true);
        api.identity.resetPassword(email, code, pwd, pwdConfirm)
            .then(r => {
                setComplete(true);
                setIsBusy(false);
                api.signout();
            }).catch(e => {
                setError(e?.response?.data ?? e?.message);
                setIsBusy(false);
            });
    }

    if (isBusy) {
        return <BusyIndicator />;
    }

    if (complete) {
        return (
            <div className="mt-4 alert alert-success">
                <p>Password successfully changed. Please <a href="/identity/login">login</a> to continue.</p>
            </div>
        );
    }

    if (emailSent) {
        return (
            <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
                <Card className="tw-w-2/5 max-lg:tw-w-3/5 max-md:tw-w-4/5 max-sm:tw-w-full tw-h-min tw-p-5" headerText="Password Reset">
                    {error && (<p className='alert alert-danger'>{error}</p>)}
                    <p>Enter the code from your email or click the link within the email.</p>
                    {code?.length === 0 && (
                        <div className='form-group'>
                            <label>Code</label>
                            <input type='text' value={code} onChange={(e) => setCode(e.currentTarget.value)} className='form-control' />
                        </div>)}
                    <div className='form-group'>
                        <label>New Password</label>
                        <input type='password' value={pwd} onChange={(e) => setPwd(e.currentTarget.value)} className='form-control' />
                    </div>
                    <div className='form-group'>
                        <label>Confirm New Password</label>
                        <input type='password' value={pwdConfirm} onChange={(e) => setPwdConfirm(e.currentTarget.value)} className='form-control' />
                    </div>
                    <div className='form-group mt-4'>
                        <button type='button' className='btn btn-primary' onClick={update}>Update password</button>
                    </div>
                </Card >
            </div >
        );
    }

    return (
        <div className="tw-flex tw-h-full tw-w-full tw-justify-center">
            <Card className="tw-w-2/5 max-lg:tw-w-3/5 max-md:tw-w-4/5 max-sm:tw-w-full tw-h-min tw-p-5" headerText="Password Reset">
                {error && (<p className='alert alert-danger'>{error}</p>)}
                <p className="tw-text-center">Enter your email address to have a password reset email sent.</p>
                <div className='form-group tw-mt-5 tw-w-full'>
                    <label>Email address</label>
                    <input type='email' value={email} onChange={(e) => setEmail(e.currentTarget.value)} className='form-control' />
                </div>
                <div className='form-group mt-4'>
                    <button type='button' className='btn btn-primary' onClick={send}>Send reset password email</button>
                </div>
            </Card>
        </div>
    );
}