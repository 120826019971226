import { useState } from "react";
import Alert from "./Alert";
import BusyIndicator from "./BusyIndicator";
import SpecialtySelector from "./SpecialtySelector";
import AccountTypeSelector from "./AccountTypeSelector";
import { useAuth, useApi } from "../security";
import { UserAccessScopes, Roles } from "../types";
import Practice from "./Practice";
import Contact from "./Contact";
import AccountDetail from "./AccountDetail";

export default function GeneralInformation(props){
    const api = useApi();
    const user = props.user || {};
    const scopes = props.scopes || [];
    const {auth} = useAuth();

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const [refresh, setRefresh] = useState(false);

    function onRoleChanged(role, checked){
        if (!checked && user.roles.includes(role)){
            const index = user.roles.indexOf(role);
            user.roles.splice(index, 1);
        } else if (checked && !user.roles.includes(role)){
            user.roles.push(role);
        }
        setRefresh(!refresh);
    }

    function onSpecialtiesChanged(specialties){
        user.specialties = specialties;
        //console.log('USER_DETAILS: Specialties changed:', {user, specialties});
        setRefresh(!refresh);
    }

    function update(){
        setIsBusy(true);

        if (user.image){
            api.users.saveAvatar(user.id, user.image)
                .then(r => {
                    doUpdate();
                })
                .catch(e => {
                    setIsBusy(false);
                    setError('Failed to upload photo. Please try again.');
                });
        } else{
            doUpdate();
        }
    }
    function doUpdate(){
        api.users.update(user)
            .then(r =>{
                setSuccess('Account information updated.');
                setIsBusy(false);
            }).catch(e => setError(e.toString()));
    }

    function resendActivation(){
        setIsBusy(true);
        api.users.activate(user.id)
            .then(r =>{
                setIsBusy(false);
                setSuccess('Activation email resent.');
            }).catch(e => {
                setIsBusy(false);
                setError(e.toString());
            });
    }

    if (isBusy){
        return (<BusyIndicator/>);
    }

    return (<div>
        <Alert error={error} success={success}/>
        
        <h3>Account</h3>
        {(UserAccessScopes.canAccess(scopes, UserAccessScopes.user.roles)
            && auth.id !== user.id) && (
                <>
                <AccountTypeSelector user={user} selected={user.roles} checkChanged={onRoleChanged}/>
                </>
            )}
        <AccountDetail user={user}/>

        {UserAccessScopes.canAccess(scopes, UserAccessScopes.user.specialties)
            && Roles.isDoctor(user.roles)
            && (
                <>
                <h3 className="mt-4">Specialties</h3>
                <SpecialtySelector hideLabel={true} selected={user.specialties} update={onSpecialtiesChanged}/>
                </>
            )}
        {UserAccessScopes.canAccess(scopes, UserAccessScopes.user.contact)
            && Roles.isDoctor(user.roles)
            && (
                <>
                <h3 className="mt-4">Contact Person</h3>
                <Contact user={user}/>
                </>
            )}
        
        <div className="mt-4">
            <div className="row">
                <div className="col">
                    {(Roles.isInternalUser(auth.roles) && !user.isActive)
                    && (<button className="btn btn-outline-primary btn-lg me-4" onClick={resendActivation}>Resend Activation Email</button>)}
                    <button className="btn btn-lg btn-primary" onClick={update}>Update</button>
                </div>
            </div>
        </div>
    </div>);
}