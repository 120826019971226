import React, { useEffect } from 'react';
import ReviewSummary from './ReviewSummary';
import './AppointmentSearchResults.css';
import { Timespan } from '../types';
import DoctorImage from './DoctorImage';
import Card from './Card';

function AppointmentDateButton(props) {
    const dt = new Date(props.date);
    const t = new Timespan(props.startTime);
    return (<button className="btn btn-primary btn-sm g-2 btn-appt-time" onClick={(e) => props.onAppointmentSelected(props.appointment)}>
        {dt.getMonth() + 1}/{dt.getDate()} {t.getDisplayString()}
    </button>);
}

function AppointmentSearchResultItem(props) {
    const item = props.item || {};
    const times = item.appointments.map((appt, index) => <AppointmentDateButton key={index} appointment={appt} date={appt.appointmentDate} startTime={appt.startTime} onAppointmentSelected={(appt) => props.onAppointmentSelected(item.doctor, appt)} />);

    return (
        <Card className="tw-w-80">
            <DoctorImage doctor={item?.doctor} />
            <div className="">
                <div className="card-body">
                    <h5 className="card-title">{item.doctor.fullName}</h5>
                    <div className="card-text"><ReviewSummary score={item.reviews?.score} count={item.reviews?.count} /></div>
                    <div className="card-text">{item.doctor.description}</div>
                    {item.locations?.map((o, i) => <div key={i} className="card-text">{o}</div>)}
                    <div className="card-text">
                        {times}
                    </div>
                </div>
            </div>
        </Card>
    );
};

function AppointmentSearchResults(props) {
    const results = props.results || { items: [] };
    const subSpecialties = props.subSpecialties || [];
    const selectedSubSpecialty = props.selectedSubSpecialty || '';

    if (results && results.items?.length === 0) {
        return (<div className='alert alert-warning'>No results found.</div>)
    }

    return (
        <div className="page">
            <div className="row">
                <div className="col-auto ms-auto m-2 search-filter tw-border-2 tw-rounded-lg">
                    Filter: <select value={selectedSubSpecialty} onChange={e => props?.onFilter(e.target.value)}>
                        <option></option>
                        {subSpecialties.map((s, i) => <option key={i} value={s.id}>{s.name}</option>)}
                    </select>
                </div>
            </div>
            <div className="tw-flex tw-flex-col tw-wrap">
                {results.items.map((item, index) => <AppointmentSearchResultItem key={index} item={item} onAppointmentSelected={props.onAppointmentSelected} />)}
            </div>
        </div>
    );
};

export default AppointmentSearchResults;