import { useEffect, useState } from "react";

function Star(props){
    const score = props?.score || 0;
    const large = props?.large || false;
    const value = props?.value || 0;
    const css = 'review-star ' + (large ? 'review-star-lg' : '');
    const empty = () => <span className={(css)} onClick={() => props?.onClick(value)}>&#x2606;&#xfe0e;</span>;
    const filled = () => <span className={css + ' filled'} onClick={() => props?.onClick(value)}>&#x2605;&#xfe0e;</span>
    return ((score >= value - 0.5) ? filled() : empty());
}

export default function ReviewStars(props){
    const [score, setScore] = useState(props?.score || 0);
    const edit = props?.edit || false;

    function updateScore(value){
        if (!edit)
            return;

        setScore(value)
        props?.onScoreChanged(value);
    }

    useEffect(() =>{
        setScore(props.score);
    },[props.score]);

    return (
        <span>
            <Star score={score} value={1} large={props.large} onClick={updateScore}/>
            <Star score={score} value={2} large={props.large} onClick={updateScore}/>
            <Star score={score} value={3} large={props.large} onClick={updateScore}/>
            <Star score={score} value={4} large={props.large} onClick={updateScore}/>
            <Star score={score} value={5} large={props.large} onClick={updateScore}/>
        </span>
        );
}