import { useEffect, useState } from "react";
import { useApi } from "../security";

export default function SpecialtySelector(props) {
    const api = useApi();
    const required = props.required || true;
    const hideLabel = props.hideLabel;

    const [selected, setSelected] = useState(props.selected || []);

    const [specialties, setSpecialties] = useState(props.specialties || []);
    const [selectedSpecialty, setSelectedSpecialty] = useState('');

    const [subSpecialties, setSubSpecialties] = useState([]);

    function onSpecialtyChanged(id) {
        if (!specialties) {
            return;
        }
        const spec = specialties.find(o => o.id === id);
        if (!spec) {
            return;
        }
        //console.log('SPECIALTY_SELECTOR: Specialty changed:', spec);
        loadSpecialty(spec);
    }

    function onSubSpecialtyChanged(sub) {
        //console.log('SPECIALTY_SELECTOR: SubSpecialty changed:', sub);

        let selections = [...selected];
        const index = selections.indexOf(sub);
        if (index >= 0) {
            // Item exists, remove it
            selections.splice(index, 1);
        } else {
            // Item does not exist, add it
            selections.push(sub);
        }
        setSelected(selections);
        props?.update(selections);
    }

    function loadSpecialty(specialty) {
        setSelectedSpecialty(specialty);
        setSubSpecialties(specialty.subSpecialties);
        setSelected([]);
        //console.log('SPECIALTY_SELECTOR: Loaded specialty:', specialty);
    }

    function loadSelections(specialty, subs) {
        let selections = [];
        for (let i = 0; i < specialty.subSpecialties.length; i++) {
            if (subs.find(o => o.id === specialty.subSpecialties[i].id)) {
                selections.push(specialty.subSpecialties[i]);
            }
        }
        setSelected(selections);
        //console.log('SPECIALTY_SELECTOR: Loaded selections:', selections);
    }

    function isChecked(sub) {
        if (!selected) {
            return false;
        }
        const item = selected.find(o => o.id === sub.id);
        return item !== null && item !== undefined;
    }

    useEffect(() => {
        if (!props.specialties || props.specialties?.length === 0) {
            api.common.specialties()
                .then(r => {
                    console.log('SPECIALTY_SELECTOR: Specialties loaded:', r.data);
                    setSpecialties(r.data);
                    const parent = r.data.find(o => o.id === props?.selected?.[0]?.parentId);
                    if (parent) {
                        const selections = [...props.selected];
                        loadSpecialty(parent);
                        loadSelections(parent, selections);
                    }
                });
        }
    }, []);

    return (
        <>
            <div className='tw-flex'>
                <div className='tw-flex-col tw-w-full'>
                    {!hideLabel && (<div>Specialties {required && <span className='text-danger'>*</span>}</div>)}
                    <select className='form-select' value={selectedSpecialty?.id} onChange={e => onSpecialtyChanged(e.target.value)}>
                        <option></option>
                        {(specialties || []).map((spec, idx) => {
                            return (<option key={idx} value={spec.id}>{spec.name}</option>);
                        })}
                    </select>
                </div>
            </div>
            <div className='tw-flex tw-my-5 tw-flex-wrap tw-w-full tw-justify-center'>
                {(subSpecialties || []).map((sub, idx) => {
                    return (
                        <div key={idx} className='tw-flex tw-flex-col tw-m-1'>
                            <input type="checkbox" className="btn-check" id={sub.id} autoComplete="off" checked={isChecked(sub)} onChange={e => onSubSpecialtyChanged(sub)} />
                            <label className="btn btn-outline-primary" htmlFor={sub.id}>{sub.name}</label>
                        </div>
                    );
                })}
            </div>
        </>
    );
}