export default function Alert(props){
    const error = props.error;
    const success = props.success;
    const warning = props.warning;

    if (error){
        return (<div className="alert alert-danger">{error}</div>)
    }
    if (warning){
        return (<div className="alert alert-warning">{warning}</div>)
    }
    if (success){
        return (<div className="alert alert-success">{success}</div>)
    }
    return (<></>);
}