import { useEffect, useRef, useState } from "react";
import {Modal as BSModal} from 'bootstrap';

function ModalHeader(props){
    const hide = props?.hideHeader || false;

    if (hide){
        return (<></>);
    }

    return (
        <div className="modal-header">
            <h5 className="modal-title">{props?.title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props?.onCancel}></button>
        </div>
    );
}

function ModalFooter(props){
    const affirmCss = props?.affirmCss || 'btn btn-primary';
    const cancelCss = props?.cancelCss || 'btn btn-secondary';
    const affirmTitle = props?.affirmTitle || 'OK';
    const cancelTitle = props?.cancelTitle || 'Cancel';

    const onAffirm = props?.onAffirm || function(){};
    const onCancel = props?.onCancel || function(){};

    const hide = props?.hideFooter || false;

    if (hide){
        return (<></>);
    }

    return (
        <div className="modal-footer">
            <button type="button" className={cancelCss} data-bs-dismiss="modal" onClick={onCancel}>{cancelTitle}</button>
            <button type="button" className={affirmCss} onClick={onAffirm}>{affirmTitle}</button>
        </div>
    );
}

export default function Modal(props){
    const modalRef = useRef(null);
    const css = 'modal fade ' + props?.css;

    useEffect(() =>{
        let modal = BSModal.getInstance(modalRef.current);

        if (!modal){
            modal = new BSModal(modalRef.current);
            modal.hide();
            modalRef.current.addEventListener('hide.bs.modal', () => props?.onCancel());
        }else{
            props?.show === true ? modal.show() : modal.hide();
        }
    },[props.show]);

    return (
        <div className={css} tabIndex='-1' role='dialog' ref={modalRef}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <ModalHeader {...props}/>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    <ModalFooter {...props}/>
                </div>
            </div>
        </div>
    );
}