import { useState } from "react";
import { useApi, useAuth } from "../security";
import Alert from "./Alert";
import './Search.css';
import BusyIndicator from "./BusyIndicator";
import ResultBox from "./ResultBox";
import ResultBoxRow from "./ResultBoxRow";

function SearchResultRow(props) {
    const result = props.result;
    const dt = new Date(result.dateAdded);
    const link = '/users/edit/' + result.id;

    return (<ResultBox headerText={result?.contactName || (result?.firstName + ' ' + result?.lastName)}>
        <ResultBoxRow name="Email:" value={result?.email} />
        <ResultBoxRow name="Registration Date:" value={dt.toDateString()} />
        <ResultBoxRow className={result?.isActive ? 'text-success' : 'text-danger'} value={result?.isActive ? 'Activated' : 'Not Activated'} />
        <a href={link} className="btn btn-primary tw-break-normal tw-mt-2">Edit</a>
    </ResultBox>)
}

function SearchResults(props) {
    const results = props.results || { items: [], totalRecords: 0, nextPage: null };

    if (results?.length === 0) {
        return (<></>);
    }

    return (<>
        <hr />
        <div className="tw-flex tw-flex-wrap tw-justify-center">
            {(results?.items || []).map((r, i) => <SearchResultRow key={i} result={r} />)}
        </div>
    </>);
}

export default function SearchUsers(props) {
    const api = useApi();
    const { auth, access } = useAuth();

    const [query, setQuery] = useState('');
    const [results, setResults] = useState();

    const [isBusy, setIsBusy] = useState(false);
    const [error, setError] = useState();

    function search() {
        setError(null);
        if (query.length < 3) {
            setError('Please enter at least 3 characters for the email address or name field.');
            return;
        }

        setIsBusy(true);
        api.users.search({ query, nextPage: results?.nextPage })
            .then(r => {
                setIsBusy(false);
                setResults(r.data);
                console.log('USERS_SEARCH:', r.data);
            }).catch(e => {
                setError(e.toString());
                setIsBusy(false);
            });
    }

    if (isBusy) {
        return (<BusyIndicator />);
    }

    return (<>
        <Alert error={error} />
        <div className="row">
            <div className="col">
                <label>Email address or name (at least 3 characters)</label>
                <input type="text" name="query" className="form-control" value={query} onChange={e => setQuery(e.target.value)} />
            </div>
        </div>
        <div className="row tw-my-3">
            <div className="col-auto ms-auto">
                <button className="btn btn-primary" onClick={search}>Find Users</button>
            </div>
        </div>
        <SearchResults results={results} />
    </>);
}