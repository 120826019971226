
export const Roles = {
    user:'user',
    doctor:'doctor',
    scheduler:'scheduler',
    internalUser:'internaluser',
    internalAdmin:'internaladmin',
    systemAdmin:'systemadmin',

    isInRole: function(roles, ...allowedRoles){
        if (!Array.isArray(roles))
            roles = [roles];
        return roles?.some(r => allowedRoles?.includes(r));
    },
    isInternalUser: function(roles){
        return this.isInRole(roles, this.internalUser, this.internalAdmin, this.systemAdmin);
    },
    isDoctor: function(roles){
        return this.isInRole(roles, this.doctor);
    },
    isScheduler: function(roles){
        return this.isInRole(roles, this.scheduler);
    },
    isDoctorOrInternalUser: function(roles){
        return this.isDoctor(roles) || this.isInternalUser(roles);
    },
    isSchedulerOrInternalUser: function(roles){
        return this.isScheduler(roles) || this.isInternalUser(roles);
    },
    isSystemAdmin: function(roles){
        return this.isInRole(roles, this.systemAdmin);
    },
    getRole(type){
        if (type === 100){
            return this.scheduler;
        } else if (type === 500){
            return this.doctor;
        }
        return this.user;
    },
    getName(role){
        if (role === this.scheduler){
            return 'Scheduler';
        } else if (role === this.doctor){
            return 'Doctor';
        }
        return 'User';
    }
};

export const AuthenticationStatus={
    authenticated:0,
    requiresPasswordChange:1,
    requiresPasswordReset:2,
    requiresEmailConfirmation:3,
    requiresMfa:4,
    error:99
};

export const AppointmentConfigurationUpdateAction = {
    repeatType:0,
    duration:1,
    name:2,
    item:3,
    all:4
};

export const AppointmentConfigurationItemUpdateAction = {
    update:0,
    add:1,
    remove:2
};

export const AppointmentReservationStatus = {
    reserved:0,
    noLongerAvailable:1,
    error:99
};

export const AppointmentStatus = {
    open:0,
    holding:1,
    pending:2,
    scheduled:3,
    cancelled:4,
    complete:5
}

export const UserAccessScopes = {
    user:{
        root:'user',
        roles:'user.roles',
        organization:'user.organization',
        specialties:'user.specialities',
        contact:'user.contact',
        manage:'user.manage',
        admin:'user.admin',
        search:'user.search'
    },
    scheduling:{
        root:'scheduling'
    },
    contract:{
        root:'contract'
    },
    payments:{
        root:'payments'
    },
    earnings:{
        root:'earnings'
    },
    audit:{
        root:'audit'
    },
    admin:{
        root:'admin'
    },

    canAccess: function(userScopes, ...requiredScopes){
        if (!Array.isArray(userScopes))
        userScopes = [userScopes];
        return userScopes?.some(r => requiredScopes?.includes(r));
    }
};

export class SearchCriteriaSortOption{
    selector = '';
    descending = false;
}

export class SearchCriteria{
    pageIndex = 0;
    pageSize = 50;
    sort = [];
}

export class AppointmentSearchCriteria extends SearchCriteria{
    specialty = '';
    subSpecialty = '';
    doctor = '';
    location = '';
    postalCode = '';
}

export class Timespan{
    hours=0;
    minutes=0;
    seconds=0;

    _date = new Date();
    constructor(value){
        this.update(value);
    }

    update(value){
        this.hours = parseInt(value?.split(':')[0] ?? '0');
        this.minutes = parseInt(value?.split(':')[1] ?? '0');
        this.seconds = parseInt(value?.split(':')[2] ?? '0');
        this._date.setHours(this.hours);
        this._date.setMinutes(this.minutes);
        this._date.setSeconds(this.seconds);
    }

    isPM(){
        return this.hours >= 12;
    }

    setHours(value){
        this._date.setHours(value);
        this._update();
        return this;
    }

    setMinutes(value){
        this._date.setMinutes(value);
        this._update();
        return this;
    }

    setSeconds(value){
        this._date.setSeconds(value);
        this._update();
        return this;
    }

    add(span){
        this.setHours(this.hours + (span?.hours || 0));
        this.setMinutes(this.minutes + (span?.minutes || 0));
        this.setSeconds(this.seconds + (span?.seconds || 0));
        return this;
    }

    addMinutes(value){
        this._date.setMinutes(this.minutes + value);
        this._update();
        return this;
    }

    getHourString(){
        return String(this.hours > 12 ? this.hours - 12 : this.hours);
    }

    getMinutesString(){
        return String(this.minutes).padStart(2, '0');
    }

    getShortString(){
        return this.getHourString() + ':' + this.getMinutesString();
    }

    getString(){
        return String(this.hours).padStart(2, '0') + ':' + this.getMinutesString() + ':' + String(this.seconds).padStart(2, '0');
    }

    getDisplayString(){
        return this.getHourString() + ':' + this.getMinutesString() + (this.isPM() ? ' PM' : ' AM');
    }

    _update(){
        this.hours = this._date.getHours();
        this.minutes = this._date.getMinutes();
        this.seconds = this._date.getSeconds();
    }
}